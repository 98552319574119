<template>
  <div class="container bg-white border rouded p-2">
    <div class="text-primary"><h2>Plazas Pendientes</h2></div>
    
    <doughnut-chart :chart-data="dataChart" :options="options" v-if="renderChart" />
  </div>
</template>

<script>
import common from "@/mixins/common";

import DoughnutChart from "@/components/charts/DoughnutChart.js";

export default {
  components: {  DoughnutChart },

  mixins: [common],

  data() {
    return {
      renderChart: false,
      dataChart: {
        labels: ["val1", "val2"],
        datasets: [
          {
            label: "My First Dataset",
            data: [10, 10],
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
            ],
            hoverOffset: 4,
          },
        ],
      },
      options: {},
    };
  },
  mounted() {
    this.ini()
  },
  methods: {
   ini(){
     const formData = new FormData();
      formData.append("action", "list");
      formData.append("t", this.getToken());

      let query = 'SELECT '
        +' (SELECT SUM(Openings) FROM JobPostings WHERE Status="open") AS openings, '
        +' (SELECT COUNT(1) FROM Applications WHERE JobPosting IN (SELECT Id FROM JobPostings WHERE Status="open")) applications'

      formData.append(
        "query",
        query
      );
      this.axios
        .post(this.$store.state.WS_URL + "/", formData)
        .then((response) => {
          //console.log('data', response.data)

          if (response.status == 200) {
            if (response.data.status == "OK") {
              let dataValues = response.data.records[0]
              let applications = dataValues.applications
              let pending = dataValues.openings - dataValues.applications
              this.dataChart.datasets[0].data = [pending, applications]
              this.dataChart.labels = [pending + " Vacantes", applications + " Aplicaciones"]
              this.renderChart = true
            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });

   }
  },
};
</script>

<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>
