<template>
  <div>
    <h1>
      <a v-if="profilePicture" 
          :href="$store.state.UPLOADS_URL + '/' + contactUploadsFolderName + '/' +profilePicture" target="_blank">
          <img 
            :src="$store.state.UPLOADS_URL + '/' + contactUploadsFolderName + '/' +profilePicture" 
            style="max-width:50px;" />
        </a>
          <b-icon-pencil-square v-else /> 
      Aplicacion
    </h1>
    
    <div class="container my-4" v-if="application">
        <div class="my-2">
            <b>Applicante:</b> 
            {{application.FirstName}} {{application.LastName}}
            <router-link :to="'/contact/'+application.Contact" class="link"> <b-icon-eye /></router-link>
        </div>

        <div class="my-2">
            <b>Plaza</b> {{application.JobPostingName}}
            <router-link :to="'/JobPosting/'+application.JobPosting" class="link"> <b-icon-eye /> </router-link>
        </div>

        <div class="my-2">
            <b>Fecha de aplicacion:</b> {{application.CreatedOn}}
        </div>

        <div class="my-2">
            <b>Status:</b> {{application.Status}}
        </div>
    </div>


    <!--<TABS>-->
      <div class="container my-2" v-if="Id">
      <div class="row">
        <b-tabs content-class="mt-3">
          <b-tab title="Respuestas" active>
            <div class="container my-4">
              <div v-for="sr in surveyResponses" :key="sr.Id" class="row border-bottom py-3 grid-row">
                <div class="col"> {{sr.QuestionLabel}}  <router-link :to="'/surveyQuestion/'+sr.Question" class="link"> <b-icon-eye /></router-link> </div>
                <div class="col"> {{sr.Response}} </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Datos de contratacion" >
            <div class="container">
              <div class="row">
                <!--<ACTIONS>-->
                  <div class="container my-3">
                      <div v-if="mode == 'view'">

                        <button class="btn btn-primary float-right" @click="mode = 'edit'">
                          <b-icon-pencil />
                        </button>
                      </div>
                      <div v-else>
                        <button class="btn btn-action float-right mx-2" @click="saveApplication">
                          <b-icon-file-check />
                        </button>
                        <button class="btn btn-secondary float-right mx-2" @click="mode = 'view'">
                          <b-icon-file-x />
                        </button>
                      </div>
                    </div>
                    <!--</ACTIONS>-->
              </div>
              <div class="row">
                <div class="col-sm">
                    <input
                      :disabled="mode == 'view'"
                      type="date"
                      class="form-control"
                      placeholder="Fecha de inicio"
                      v-model="applicationData.StartDate"
                    />
                </div>
                <div class="col-sm">
                    <input
                      :disabled="mode == 'view'"
                      type="date"
                      class="form-control"
                      placeholder="Fecha de terminacion"
                      v-model="applicationData.EndDate"
                    />
                </div>
                <div class="col-sm">
                    <b-form-select
                      v-model="applicationData.Status"
                      class="form-select"
                      :options="applicationStatus"
                      :disabled="mode == 'view'"
                    />
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Notas">
            <div class="row">
              <div class="col-sm my-3">
                <textarea
                  id="Note"
                  type="text"
                  class="form-control my-2"
                  placeholder="Agregar una nota"
                  v-model="noteData.Note"
                />
                
                <button
                  class="btn btn-action float-right my-2"
                  @click="saveNote"
                >
                  <b-icon-file-check />
                </button>
              </div>
              <div class="col-sm my-3">
                
              </div>
            </div>

            <div class="row">
              <div class="container">
                <div v-for="iNote in notes" :key="iNote.Id" class="row border-bottom py-3 grid-row">
                  <div class="col-sm-9"> {{iNote.Note}} <b-icon-pencil class="link" @click="editNote(iNote.Id)" />  </div>
                  <div class="col-sm"> 
                    <span v-html="getUsername(iNote.CreatedBy)" />
                    <br>
                    {{iNote.CreatedOn}} 
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          
        </b-tabs>
      </div>

    </div>
    <!--</TABS>-->
  </div>
</template>

<script>
import common from "@/mixins/common";
export default {
  data() {
    return {
        logPrefix: "Applications",
        application: null,
        surveyResponses: null,
        Id: null,
        profilePicture: null,

        mode: 'view',

        applicationData: {},

        NoteId: null,
        noteData:{
          Note: null,
          ObjectType: 'Application',
          ObjectId: null,
        },
        notes: null,
      };
  },
  computed: {
    contactUploadsFolderName(){
      return 'Contact-' + this.application.Contact
    }
  },
  methods: {
    getApplication() {
      const formData = new FormData()
      formData.append("action", "list")
      formData.append("t", this.getToken())
      formData.append("query", 'SELECT * FROM Applications_Basic_Info WHERE Id = "' + this.Id + '"')
      this.axios
        .post(this.$store.state.WS_URL + "/", formData)
        .then((response) => {

          //console.log('data', response.data)

          if (response.status == 200) {

            if (response.data.status == "OK") {
              this.application = response.data.records[0]

              this.applicationData.StartDate = this.application.StartDate; 
              this.applicationData.EndDate = this.application.EndDate; 
              this.applicationData.Status = this.application.Status; 

              this.setPageTitle("Application " + this.application.FirstName)
              this.getFiles()
              this.getNotes()
            } else {
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },
    getSurveyResponses() {
      const formData = new FormData()
      formData.append("action", "list")
      formData.append("t", this.getToken())
      formData.append("query", 'SELECT * FROM JobPostingSurveyResponsesInfo WHERE Application = "' + this.Id + '"')
      this.axios
        .post(this.$store.state.WS_URL + "/", formData)
        .then((response) => {

          //console.log('data', response.data)

          if (response.status == 200) {

            if (response.data.status == "OK") {
              this.surveyResponses = response.data.records
            } else {
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },
    saveApplication() {
      const applicationFormData = new FormData();
      applicationFormData.append("action", "upsert");
      applicationFormData.append("t", this.getToken());
      applicationFormData.append("table", "Applications");
      applicationFormData.append("Id", this.Id);

      applicationFormData.append("fields", JSON.stringify(this.applicationData));

      this.axios
        .post(this.$store.state.WS_URL + "/", applicationFormData)
        .then((response) => {
          //console.log(response.data);

          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.mode = 'view'
            } else {
              alert(
                  "Problemas al guardar contacto: " + response.data.message
                )
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },
    saveNote() {
      const contactData = new FormData();
      contactData.append("action", "upsert");
      contactData.append("t", this.getToken());
      contactData.append("table", "Notes");
      contactData.append("Id", this.NoteId);

      this.noteData.ObjectId = this.Id
      contactData.append("fields", JSON.stringify(this.noteData));

      this.axios
        .post(this.$store.state.WS_URL + "/", contactData)
        .then((response) => {
          //console.log(response.data);

          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.noteData.Note = null
              this.noteId = null
              this.getNotes()
            } else {
              alert(
                  "Problemas al guardar nota: " + response.data.message
                );
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });
    },
    getNotes() {
      const contactData = new FormData();
      contactData.append("action", "list");
      contactData.append("t", this.getToken());
      contactData.append("query", "SELECT * FROM Notes WHERE ObjectId = '" + this.Id + "' ORDER BY CreatedOn DESC" );
      this.axios
        .post(this.$store.state.WS_URL + "/", contactData)
        .then((response) => {
          //console.log(response.status, response.data);
          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.notes = response.data.records;
            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });
    },
    editNote(noteId){
      //console.log('editNote', noteId)
      this.notes.forEach( (element) => {
          if (element.Id == noteId){
              this.NoteId = element.Id
              this.noteData = element
          }
      })
      document.getElementById("Note").scrollIntoView();

    },
    getFiles(){
      const fileData = new FormData();
      fileData.append("action", "listFiles");
      fileData.append("t", this.getToken());
      fileData.append("folder", this.contactUploadsFolderName );
      this.axios
        .post(this.$store.state.WS_URL + "/", fileData)
        .then((response) => {
          //console.log(response.status, response.data);
          if (response.status == 200) {
            if (response.data.status == "OK") {
              response.data.files.forEach( (iFile) => {
                if (iFile.includes('foto')){
                  this.profilePicture = iFile
                }
              })
              
            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });
    },
  },
  mounted: function () {
    this.Id = this.$route.params.Id
    this.getApplication()
    this.getSurveyResponses()

  },
  mixins: [common],
};
</script>

<style></style>
