<template>
  <div class="shadow">
    <div class="row">
      <div class="col-7">
        

        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="textToSearch"
            placeholder="Busqueda rapida de contacto"
            aria-label="Busqueda rapida de contacto"
            aria-describedby="Busqueda rapida de contacto"
            v-on:keyup.enter="searchText"
          />
          <span class="input-group-text bg-primary text-white" 
            id="basic-addon2" 
            @click="searchText"
            >
              <b-icon-search/>
          </span>
        </div>
        
      </div>

      <div class="col">
        <div class="float-right">
          <b-icon-person-circle/> {{ $store.getters.user.Username }} <span class="link" @click="logout">(Salir)</span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import common from "@/mixins/common";

export default {

  data() {
      return {
          logPrefix: "Header",
          textToSearch: null,
          };
  },
  
  methods: {
    onResize() {
      this.$store.commit("windowProps", window);
    },

    onMouseOver(){
      this.refreshToken()

      this.$store.commit("lastUserActivity")
    },

    getUsers() {
      const formData = new FormData()
      formData.append("action", "list")
      formData.append("t", this.getToken())
      formData.append("query", 'SELECT Id, Username  FROM Users')
      this.axios
        .post(this.$store.state.WS_URL + "/", formData)
        .then((response) => {

          //console.log('data', response.data)

          if (response.status == 200) {

            if (response.data.status == "OK") {
              this.$store.commit("users", response.data.records);
            }

          } else {
            console.log(response);
          }
        });
    },

    searchText(){
      this.$router.push('/searchresults/'+this.textToSearch)
      this.textToSearch = null
    }

  },

  mounted() {
    this.$store.commit("windowProps", window)

    //We get to users so we avoid querying the DB multimple times for System Properties fields 
    this.getUsers()


    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize)
      window.addEventListener("mouseover", this.onMouseOver)
    });
  },

  mixins: [common],

};
</script>

<style>
.shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
  margin-bottom: 1.5rem !important;
}
</style>
