<template>
  <div class="text-white">
      <div class="my-5 py-3 bg-white"> 
          <img :src="$store.state.IMG_URL+'/logo.png'" 
          class="mx-auto d-block img-fluid" 
          style="max-width: 50px;" /> 
      </div>
      
      <hr class="my-5" />
      <router-link to="/">
        <div class="row my-4 px-2">  
          <div class="col-md-2 text-center"><b-icon-grid-fill /></div>
          <div class="col d-md-none d-lg-block">Dashboard </div>
        </div>
      </router-link>

      <router-link to="/contacts">
        <div class="row my-4 px-2">  
          <div class="col-md-2 text-center"><b-icon-people /></div>
          <div class="col d-md-none d-lg-block">Contactos </div>
        </div>
      </router-link> 

      <router-link to="/clients">
        <div class="row my-4 px-2">  
          <div class="col-md-2 text-center"><b-icon-award /></div>
          <div class="col d-md-none d-lg-block">Clientes </div>
        </div>
      </router-link> 

      <router-link to="/jobpostings">
        <div class="row my-4 px-2">  
          <div class="col-md-2 text-center"><b-icon-file-earmark-ruled/></div>
          <div class="col d-md-none d-lg-block">Plazas </div>
        </div>
      </router-link> 

      <router-link to="/SurveyQuestions">
        <div class="row my-4 px-2">  
          <div class="col-md-2 text-center"> 
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
            </svg> </div>
          <div class="col d-md-none d-lg-block">Preguntas</div>
        </div>
      </router-link> 

      <router-link to="/applications">
        <div class="row my-4 px-2">  
          <div class="col-md-2 text-center"><b-icon-pencil-square/></div>
          <div class="col-md d-md-none d-lg-block">Aplicaciones </div>
        </div>
      </router-link> 

      <router-link to="/campaigns">
        <div class="row my-4 px-2">  
          <div class="col-md-2 text-center"><b-icon-chat-left-text /></div>
          <div class="col d-md-none d-lg-block">Campaña SMS </div>
        </div>
      </router-link> 

      
      <hr class="my-4" />
      <router-link to="/" class="d-none">
        <div class="row my-4 px-2">  
          <div class="col-2"><b-icon-clipboard-data /></div>
          <div class="col d-md-none d-lg-block">Reportes</div>
        </div>
      </router-link> 

      <router-link to="/"  class="d-none">
        <div class="row my-4 px-2">  
          <div class="col-2"><b-icon-cloud-arrow-down /></div>
          <div class="col d-md-none d-lg-block">Exportar</div>
        </div>
      </router-link> 
      
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>