import Vue from 'vue'
import App from './App.vue'

/** Vuex **/
import store from './store/index'

/** Axios **/
import axios from 'axios';
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)


/** Router **/
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import { routes } from '@/routes'
const router = new VueRouter({
  routes,
  // mode: 'history'
});

/**Bootstrap */
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/styles/custom.scss'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


/**Rich Text Editor */
// https://github.com/hifarer/vueditor
import Vueditor from 'vueditor'
import 'vueditor/dist/style/vueditor.min.css'
let config = {
  toolbar: [
    'removeFormat', 'undo', 'redo', '|', 'element', 'foreColor', 'backColor', 'divider', 'bold', 'italic', 'underline',
        'link', 'unLink', 'divider', 'divider', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull',
        '|', 'indent', 'outdent', 'insertOrderedList', 'insertUnorderedList', '|', 'fullscreen', 'sourceCode', 'markdown'
  ],
  fontName: [
    {val: 'arial black'}, 
    {val: 'times new roman'}, 
    {val: 'Courier New'},
  ],
  fontSize: ['12px', '14px', '16px', '18px', '0.8rem', '1.0rem', '1.2rem', '1.5rem', '2.0rem'],
  uploadUrl: ''
};
Vue.use(Vueditor, config);

/**productionTip */
Vue.config.productionTip = false

import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDBVtxwoC-a0XFQcZN8XhX15aLZBYdD2Ps', /**EdGonAmy */
    libraries: 'places',
  }
});


/*
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
*/




new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')