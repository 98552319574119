<template>
  <div>
    <div class="container">
      <h1 v-if="Id"><b-icon-file-earmark-ruled /> Campaña SMS</h1>
      <h1 v-else><b-icon-plus-circle /> Nueva Campaña SMS</h1>
    </div>

    <!--<ACTIONS>-->
    <div class="container my-3">

      <div v-if="campaignData.SentOn">
          Enviada el: {{campaignData.SentOn}}
      </div>
      <div v-else-if="mode == 'view'">

        <button class="btn btn-primary float-right" @click="mode = 'edit'">
          <b-icon-pencil />
        </button>

        <button class="btn btn-danger float-right mx-2" @click="deleteCampaign">
          <b-icon-file-earmark-minus-fill />
        </button>

        <button class="btn btn-action float-right mx-2" @click="send">
          <b-icon-broadcast /> Enviar
        </button>

      </div>
      <div v-else>
        <button class="btn btn-action float-right mx-2" @click="saveCampaign">
          <b-icon-file-check />
        </button>
        <button class="btn btn-secondary float-right mx-2" @click="cancel">
          <b-icon-file-x />
        </button>
      </div>
    </div>
    <!--</ACTIONS>-->

    <div style="clear: both"></div>

    <!--<FORM_DATA>-->
    <div class="container">
      <div class="row">
        <div class="col-sm my-3">
          <input
            :disabled="mode == 'view'"
            type="text"
            class="form-control"
            placeholder="Nombre"
            v-model="campaignData.Name"
          />
        </div>

        <div class="col-sm my-3">
          <b-form-select
            v-model="campaignData.JobPosting"
            class="form-select"
            :options="jobPostings"
            :disabled="mode == 'view'"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-sm">

          <div v-if="selectedJobPosting">
            <i> 
              {{selectedJobPublicPostingUrl}}
            </i>
            <a :href="selectedJobPublicPostingUrl" target="_blank"><b-icon-link /></a>

          </div>

          <div v-if="mode != 'view'" v-html="contentHelpText" />

          <textarea
              type="text"
              rows="5"
              class="form-control my-2"
              placeholder="Mensaje"
              :disabled="mode == 'view'"
              v-model="campaignData.Content"
            />

          <span v-html="'Characters: ' + messsageLength + '/160 || Segments: '+Math.ceil(messsageLength/160)"
             class="float-right text-grey-900"
              />

        </div>
      </div>
    </div>
    <!--</FORM_DATA>-->

    <!--<TABS>-->
    <div class="container my-2" v-if="Id">
      <div class="row">
        <b-tabs content-class="mt-3">
          <b-tab title="Filtros" active>

            <div class="container">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="includeEmployees" 
                  v-model="includeEmployees"
                  @change="getCampaignRecipientsCandidates">
                <label class="form-check-label" for="includeEmployees">
                  Incluir empleados
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox"
                  id="includeContactsWithoutApplications" 
                  v-model="includeContactsWithoutApplications"
                  @change="getCampaignRecipientsCandidates">
                <label class="form-check-label" for="includeContactsWithoutApplications">
                  Incluir contactos sin applicaciones
                </label>
              </div>
            </div>

            <!--<CAMPAIGN_FILTERS>-->
            <div class="container">
              <div v-for="campaignFilter in campaignFilters" :key="campaignFilter.Id" :id="campaignFilter.Id" class="row border-bottom py-3 grid-row">
                <div class="col-sm-9"> 
                      <div v-html="getSurveyQuestion(campaignFilter.Question).Question + ' = ' + campaignFilter.Content" /> 
                      <div v-if="!campaignData.SentOn">
                        <b-icon-pencil class="link" @click="editCampaignFilter(campaignFilter)" />  
                        <b-icon-file-earmark-minus-fill class="link danger" @click="deleteCampaignFilter(campaignFilter)" />  
                      </div>
                </div>
                <div class="col-sm"> 
                  <span v-html="getUsername(campaignFilter.CreatedBy)" />
                  <br>
                  {{campaignFilter.CreatedOn}} 
                </div>
              </div>
            </div>
            <!--</CAMPAIGN_FILTERS>-->

            <!--<ADD_FILTER>-->
            <div class="container" id="new-filter" v-if="!campaignData.SentOn">
              <div class="row">
                <div class="col-sm">
                   <b-form-select
                      v-model="campaignFilterData.Question"
                      class="form-select my-2"
                      :options="surveySurveyQuestions"
                    />
                </div>
                <div class="col-sm">
                   <input
                      type="text"
                      class="form-control my-2"
                      placeholder="Filtro"
                      v-model="campaignFilterData.Content"
                    />
                </div>

                <div class="col-sm-1">
                  <button class="btn btn-primary my-2 float-right" @click="saveCampaignFilter">
                    <b-icon-plus-circle />
                  </button>
                </div>
              </div>

            </div>
            <!--</ADD_FILTER>-->

          </b-tab>

          <b-tab :title="'Candidatos ('+campaignRecipientsCandidates.length+')'" v-if="campaignRecipientsCandidates.length>0">
            <div class="container">
              <div v-for="candidate in campaignRecipientsCandidates" :key="candidate.Contact" class="row border-bottom py-3 grid-row">
                <div class="col"> {{candidate.FirstName}} {{candidate.LastName}}  </div>
                <div class="col"> {{candidate.Cellphone}} </div>
                <div class="col"> <router-link :to="'/Contact/'+candidate.Contact"> <b-icon-eye /> </router-link> </div>
              </div>
            </div>
          </b-tab>

          <b-tab :title="'Destinatatios ('+campaignRecipients.length+')'" v-if="campaignRecipients.length>0">
            <div class="container">
              <div v-for="recipient in campaignRecipients" :key="recipient.Contact" class="row border-bottom py-3 grid-row">
                <div class="col"> {{recipient.FirstName}} {{recipient.LastName}}  </div>
                <div class="col"> {{recipient.Cellphone}} </div>
                <div class="col"> {{recipient.Status}} </div>
                <div class="col"> {{recipient.TwilioResponse}} </div>
                <div class="col"> <router-link :to="'/Contact/'+recipient.Contact"> <b-icon-eye /> </router-link> </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Enviar mensaje de prueba" v-if="mode=='view'">

            <div class="container">
              <div class="row">
                <div class="col-sm-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Celular - e.g. 5057442345"
                    v-model="testSms.Cellphone"
                    @change="validatePhone($event)"
                  />
                </div>
                <div class="col-sm-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nombre"
                    v-model="testSms.FirstName"
                  />
                </div>

                <div class="col-sm">
                   <button class="btn btn-primary mx-2" @click="sendSmsTest">
                    <b-icon-broadcast /> Enviar prueba
                  </button>
                </div>
              </div>

            </div>

          </b-tab>
        </b-tabs>
      </div>

    </div>
    <!--</TABS>-->

    
    <!--<SYSTEM_PROPS>-->
    <div v-if="Id" class="container border-top border-bottom py-3 text-grey-900" >
      <h4>Propiedades del sistema</h4>

      <div class="row">
        <div class="col-sm-2 font-weight-bold">Id</div>
        <div class="col-sm">
          <span v-html="this.Id" />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2 font-weight-bold">Creacion</div>
        <div class="col-sm">
          <span v-html="campaignData.CreatedOn" />
        </div>

        <div class="col-sm-2 font-weight-bold">Creado por</div>
        <div class="col-sm">
          <span v-html="getUsername(campaignData.CreatedBy)" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 font-weight-bold">Ultima modificacion</div>
        <div class="col-sm">
          <span v-html="campaignData.ModifiedOn" />
        </div>

        <div class="col-sm-2 font-weight-bold">Modificado por</div>
        <div class="col-sm">
          <span v-html="getUsername(campaignData.ModifiedBy)" />
        </div>
      </div>
    </div>
    <!--</SYSTEM_PROPS>-->
  </div>
</template>

<script>
import common from "@/mixins/common";

export default {
  data() {
    return {
      logPrefix: "NewCampaign",
      mode: "view",
      
      Id: null,
      testSms: {
        Cellphone: null,
        Content: null,
        FirstName: null,
        JobPostingName: null,
        Slug: null,
      },

      contentHelpText: "Palabras clave: [FIRST_NAME], [JOB_NAME], [LINK]",

      campaignData: {
        Name: "",
        Content: "[FIRST_NAME], SantiEmpleo busca [JOB_NAME]. Ve a [LINK] y aplica. Responde STOP si ya no quieres recibir ofertas de trabajo.",
      },

      campaignFilterData:{
        Content: ""
      },

      jobPostings: [],
      surveySurveyQuestions: [],
      campaignFilters: [],
      campaignRecipientsCandidates: [],
      campaignRecipients: [],

      includeEmployees: false,
      includeContactsWithoutApplications: false,
      
    };
  },

  computed: {
    selectedJobPosting(){
      let jobPosting = null
      this.jobPostings.forEach( (jp) => {
        //console.log(jp.Id,  this.campaignData.JobPosting)
          if (jp.Id == this.campaignData.JobPosting) {
            //console.log(jp)
            jobPosting =  jp
          }
      })
      return jobPosting
    },

    selectedJobPublicPostingUrl(){
      if (this.selectedJobPosting){
        return this.$store.state.PUBLIC_SITE_URL + '/job/' + this.selectedJobPosting.Slug
      }
      return null
    },

    messsageLength(){
      let messsageLength = 0

      if (this.selectedJobPosting){
        messsageLength = this.campaignData.Content.length

        if (this.campaignData.Content.includes("[JOB_NAME]")) {
          messsageLength += this.selectedJobPosting.Name.length
        }

        if (this.campaignData.Content.includes("[LINK]")) {
          messsageLength += this.selectedJobPublicPostingUrl.length - "[LINK]".length
        }
      }

      return messsageLength
    }
  },

  methods: {
    saveCampaign() {
      const campaignFormData = new FormData();
      campaignFormData.append("action", "upsert");
      campaignFormData.append("t", this.getToken());
      campaignFormData.append("table", "Campaigns");
      campaignFormData.append("Id", this.Id);
      campaignFormData.append("fields", JSON.stringify(this.campaignData));

      this.axios
        .post(this.$store.state.WS_URL + "/", campaignFormData)
        .then((response) => {
          //console.log(response.status, response.data, this.logPrefix + '>getSurveySurveyQuestions');

          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.Id = response.data.Id;
              this.mode = 'view'
              this.getCampaign()
            } else {
              
              alert(
                "Problemas al guardar Campaña SMS: " + response.data.message
              )
              
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },

    send(){
      if (confirm('Seguro que desea enviar Campaña SMS?')) {
        this.sendCampaignToRecipients()
      }
    },

    sendCampaignToRecipients() {

      const campaignRecipientFormData = new FormData()
      campaignRecipientFormData.append("action", "sendCampaignToRecipients")
      campaignRecipientFormData.append("t", this.getToken())
      campaignRecipientFormData.append("campaignId", this.Id)
      campaignRecipientFormData.append("campaignRecipientsCandidates", JSON.stringify(this.campaignRecipientsCandidates))

      this.axios
        .post(this.$store.state.WS_URL + "/", campaignRecipientFormData)
        .then((response) => {
          console.log(response.status, response.data, this.logPrefix + '>sendCampaignToRecipients');

          if (response.status == 200) {
            if (response.data.status == "OK") {

              this.campaignData.SentOn = 'CURRENT_TIMESTAMP'
              this.saveCampaign()

              this.getCampaignRecipients()

            } else {
              
              alert(
                "Problemas al enviar campaña a destinatarios: " + response.data.message
              )
              
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });

    },

    sendSmsTest(){
      const sendSmsTestFormData = new FormData()
      sendSmsTestFormData.append("action", "sendSms")
      sendSmsTestFormData.append("t", this.getToken())

      this.testSms.JobPostingName = this.selectedJobPosting.Name
      this.testSms.Slug = this.selectedJobPosting.Slug
      this.testSms.Content = this.campaignData.Content

      sendSmsTestFormData.append("sms", JSON.stringify(this.testSms))

      this.axios
        .post(this.$store.state.WS_URL + "/", sendSmsTestFormData)
        .then((response) => {
          console.log(response.status, response.data, this.logPrefix + '>sendSmsTest');

          if (response.status == 200) {
            if (response.data.status == "OK") {

              alert( "Mensaje enviado")

            } else {
              
              alert("Problemas al enviar mensaje de prueba: " + response.data.message)
              
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },

    saveCampaignFilter() {
      const campaignFilterFormData = new FormData();
      campaignFilterFormData.append("action", "upsert");
      campaignFilterFormData.append("t", this.getToken());
      campaignFilterFormData.append("table", "CampaignFilters");
      campaignFilterFormData.append("Id", this.campaignFilterData.Id);
      this.campaignFilterData.Campaign = this.Id
      campaignFilterFormData.append("fields", JSON.stringify(this.campaignFilterData));

      this.axios
        .post(this.$store.state.WS_URL + "/", campaignFilterFormData)
        .then((response) => {
          //console.log(response.status, response.data, this.logPrefix + '>getSurveyQuestions');

          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.campaignFilterData = {
                  Content: ""
                }
              this.getCampaignFilters()
            } else {
              
              alert(
                "Problemas al guardar Filtro: " + response.data.message
              )
              
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },

    deleteCampaign() {
      if (confirm('Seguro que desea borrar Campaña SMS?')) {
        this.campaignData.RecordStatus = 'deleted'
        this.campaignData.Name = this.campaignData.Name + '-DELETED-' + this.campaignData.Id
        this.saveCampaign()
        this.$router.push('/Campaigns')
      }
    },

    getCampaign() {
      const campaignData = new FormData();
      campaignData.append("action", "list");
      campaignData.append("t", this.getToken());
      campaignData.append("query", "SELECT * FROM Campaigns WHERE Id = '" + this.Id + "'");
      this.axios
        .post(this.$store.state.WS_URL + "/", campaignData)
        .then((response) => {
          //console.log(response.status, response.data);
          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.campaignData = response.data.records[0]
              this.setPageTitle( this.campaignData.Name )

              this.getCampaignFilters()

              this.getCampaignRecipients()
              
            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });
    },

    getJobPostings() {
      const formData = new FormData()
      formData.append("action", "list")
      formData.append("t", this.getToken())
      formData.append("query", 'SELECT * FROM JobPostings WHERE RecordStatus!="deleted" AND Status = "open" ')
      this.axios
        .post(this.$store.state.WS_URL + "/", formData)
        .then((response) => {

          //console.log('data', response.data)

          if (response.status == 200) {

            if (response.data.status == "OK") {
              response.data.records.forEach( (jobPostingItem) => {
                  jobPostingItem.value = jobPostingItem.Id
                  jobPostingItem.text = jobPostingItem.Name
              })
              this.jobPostings = response.data.records
              
            } else {
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },

    getSurveyQuestions() {
      this.surveySurveyQuestions = []

      const contactData = new FormData();
      contactData.append("action", "list");
      contactData.append("t", this.getToken());
      contactData.append("query", "SELECT * FROM SurveyQuestions WHERE RecordStatus = 'active' ORDER BY CreatedOn ASC " );
      this.axios
        .post(this.$store.state.WS_URL + "/", contactData)
        .then((response) => {
          //console.log(response.status, response.data, this.logPrefix + '>getSurveyQuestions');
          if (response.status == 200) {
            if (response.data.status == "OK") {
              response.data.records.forEach( (surveyQuestion) => {
                  surveyQuestion.value = surveyQuestion.Id
                  surveyQuestion.text = surveyQuestion.Question
                  surveyQuestion.disabled = false
              })
              this.surveySurveyQuestions = response.data.records

            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });
    },

    getSurveyQuestion(_surveyQuestionId){
      //console.log('_surveyQuestionId', _surveyQuestionId, this.logPrefix + '>getSurveyQuestion')
      //console.log('this.surveySurveyQuestions', this.surveySurveyQuestions, this.logPrefix + '>getSurveyQuestion')
      
      let surveyQuestionFound = this.surveySurveyQuestions.find( ({ Id }) => Id === _surveyQuestionId )

      //console.log('surveyQuestionFound', surveyQuestionFound, this.logPrefix + '>getSurveyQuestion')

      if (surveyQuestionFound == undefined || surveyQuestionFound == null){
        surveyQuestionFound = {Question: 'NOT FOUND', Type: null}
      }

      return surveyQuestionFound
    },

    getCampaignFilters() {
      this.campaignFilters = []

      const contactData = new FormData();
      contactData.append("action", "list");
      contactData.append("t", this.getToken());
      contactData.append("query", "SELECT * FROM CampaignFilters WHERE RecordStatus = 'active' AND Campaign = '"+this.Id+"' ORDER BY CreatedOn ASC " );
      this.axios
        .post(this.$store.state.WS_URL + "/", contactData)
        .then((response) => {
          //console.log(response.status, response.data, this.logPrefix + '>getCampaignFilters');
          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.campaignFilters = response.data.records
              
            } else {
              this.handleWsErr(response.data);
            }

            if (!this.campaignData.SentOn){
              this.getCampaignRecipientsCandidates()
            }

          } else {
            console.log(response);
          }
        });
    },

    editCampaignFilter(_campaignFilter){
      //console.log('_jobPostingSurveyQuestion', _jobPostingSurveyQuestion, this.logPrefix + '>editJobPostingSurveyQuestion')

      this.campaignFilterData = _campaignFilter

      //console.log('this.jobPostingSurveyQuestionData', this.jobPostingSurveyQuestionData, this.logPrefix + '>editJobPostingSurveyQuestion')

      document.getElementById("new-filter").scrollIntoView()

    },

    deleteCampaignFilter(_campaignFilterData) {
      if (confirm('Seguro que desea borrar el filtro?')) {
        this.campaignFilterData = _campaignFilterData
        this.campaignFilterData.RecordStatus = 'deleted'
        this.saveCampaignFilter()
      }
    },

    getCampaignRecipientsCandidates() {
      this.campaignRecipientsCandidates = []

      const campaignRecipientsCandidatesFormData = new FormData()
      campaignRecipientsCandidatesFormData.append("action", "list")
      campaignRecipientsCandidatesFormData.append("t", this.getToken())

      let query = ''

      if (this.includeContactsWithoutApplications){
        query += "SELECT Id Contact, FirstName, LastName, Cellphone FROM Contacts WHERE  SmsConsent = 'subscribed' AND RecordStatus = 'active' AND Id NOT IN (SELECT Contact FROM Applications) "
      }

      if (this.includeEmployees){
        if (query != ''){
          query += " UNION DISTINCT "
        }
        query += "SELECT Id Contact, FirstName, LastName, Cellphone FROM Contacts WHERE  Status = 'employed' AND RecordStatus = 'active' "
      }

      if (query != ''){
        query += " UNION DISTINCT "
      }
      query += "SELECT DISTINCT Contact, FirstName, LastName, Cellphone FROM JobPostingSurveyResponsesInfo WHERE SmsConsent = 'subscribed'  ";
      this.campaignFilters.forEach( (filter) => {
          query += " AND Response = '" + filter.Content + "'"
          query += " AND Question = '" + filter.Question + "'"
      })

      console.log('query', query);

      campaignRecipientsCandidatesFormData.append("query", query);


      this.axios
        .post(this.$store.state.WS_URL + "/", campaignRecipientsCandidatesFormData)
        .then((response) => {
          //console.log(response.status, response.data);
          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.campaignRecipientsCandidates = response.data.records;
            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });
    },

    getCampaignRecipients() {
      this.campaignRecipients = []
      this.campaignRecipientsCandidates = []

      const campaignRecipientsFormData = new FormData();
      campaignRecipientsFormData.append("action", "list");
      campaignRecipientsFormData.append("t", this.getToken());

      let query = "SELECT * FROM CampaignRecipientsInfo WHERE  Campaign = '"+this.Id+"' "
     

      //console.log('query', query);

      campaignRecipientsFormData.append("query", query);


      this.axios
        .post(this.$store.state.WS_URL + "/", campaignRecipientsFormData)
        .then((response) => {
          //console.log(response.status, response.data);
          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.campaignRecipients = response.data.records;
            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });
    },
    
    cancel(){
      if (this.mode == 'edit'){
        this.mode = 'view'
        this.getCampaign()
      }else if (this.mode == 'new'){
        this.$router.push('/Campaigns')
      }
    },


  },

  mounted: function () {
    if (this.$route.params.Id != undefined) {
      this.Id = this.$route.params.Id
      this.getCampaign()
    } else {
      this.mode = "new"
      this.setPageTitle("New Campaign")
    }

    this.getJobPostings()
    this.getSurveyQuestions()
  },

  mixins: [common],
};
</script>

<style></style>
