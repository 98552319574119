export default{
    data() {
        return {
            logPrefix: "Mixin.common",
            phoneExp: /(\d{3})(\d{3})(\d{3})/,

            optionsSmsConsent: [
                { value: "pending", text: "Pendiente" },
                { value: "subscribed", text: "Suscrito" },
                { value: "selfRemoved", text: "Auto Removido" },
            ],

            applicationStatus: [
                { value: "new", text: "Nuevo" },
                { value: "hired", text: "Contratado" },
                { value: "terminated", text: "Terminado" },
                { value: "fired", text: "Despedido" },
            ],

            surveyQuestionTypes:[
                { value: "text", text: "Texto" },
                { value: "boolean", text: "Falso / Verdadero" },
                { value: "select", text: "Seleccion" },
            ],
        };
    },


    computed: {
        
    },

    methods: {
        getToken(){
            //console.log('mixin.common.getToken', this.$store.getters.user.token)
            return this.getCookie("token")
        },

        optionsSmsConsentLabel(_optionSmsConsent) {
            let optionSmsConsentLabel = _optionSmsConsent; 
            this.optionsSmsConsent.forEach( (element) =>  {
                if (element.value == _optionSmsConsent){
                    optionSmsConsentLabel = element.text
                }
            })
            return optionSmsConsentLabel; 
        },

        getUsername(id){
            //console.log('mixin.common.getUsername', id)

            let userName = ""

            if (this.$store.getters.users != null){

                this.$store.getters.users.forEach(function(element) {
                    if (element.Id == id){
                        userName = element.Username
                    }
                })
            }

            return userName
        },

        handleWsErr(responseData){
            //console.log('mixin.common.handleWsErr', responseData)

            if (responseData.status == 'TOKEN_ERR'){
                this.logout()
            }

        },

        async refreshToken() {
            let token = this.getCookie("token")
            
            let refreshTokenAfterMinutes = 60
            let refreshTokenAfterMiliseconds = (refreshTokenAfterMinutes * 60000) 

            let iddleTime = Date.now() - this.$store.getters.lastUserActivity
            let lastTokenRefresh = (Date.now() - this.$store.getters.lastTokenRefresh)

            if (lastTokenRefresh >= (refreshTokenAfterMiliseconds - 60000)){
                console.log("####ONE MIN TO TOKEN EVAL####", this.logPrefix+">refreshToken")
            }

            if (lastTokenRefresh >= refreshTokenAfterMiliseconds){
                console.log("####TOKEN EVAL####", this.logPrefix+">refreshToken")
                console.log("token", token, this.logPrefix+">refreshToken")
                console.log("iddleTime", iddleTime, this.logPrefix+">refreshToken")
                console.log("lastTokenRefresh", lastTokenRefresh, this.logPrefix+">refreshToken")
                console.log("this.$store.getters.lastTokenRefresh", this.$store.getters.lastTokenRefresh, this.logPrefix+">refreshToken")
            }
            
            

            /**
             * Refresh token 4mins after last refresh and max 5 mins of page inactivity  
             */
            if (token != undefined && token != null && token != 'null'
                && lastTokenRefresh >= refreshTokenAfterMiliseconds
                && iddleTime < 3000000
                ){
                
                console.log("#### TO RENEW THE TOKEN####", this.logPrefix+">refreshToken")
                this.setCookie("token", 'null')

                const formData = new FormData();
                formData.append('action', 'refresh_token');
                formData.append('t', token);
                let tokenRepreshResponse = await this.axios.post(this.$store.state.WS_URL + '/', formData)
                
                

                if (tokenRepreshResponse.status == 200) {
                    if (tokenRepreshResponse.data.status == 'OK'){
                        this.$store.commit("user", tokenRepreshResponse.data.user)
                        this.setCookie("token", tokenRepreshResponse.data.user.token)
                        this.$store.commit("lastTokenRefresh", Date.now())
                    }else{
                        console.log("#### TO LOGOUT (STATUS ERR)####", this.logPrefix+">refreshToken")

                        this.handleWsErr(tokenRepreshResponse.data)
                    }
                    
                } else {

                    console.log("#### TO LOGOUT (RESPONSE ERR)####", this.logPrefix+">refreshToken")
                    console.log(tokenRepreshResponse)

                    console.log(tokenRepreshResponse);
                }


            }else if (iddleTime >= 3000000){
                console.log("#### TO LOGOUT (iddleTime)####", this.logPrefix+">refreshToken")

                this.logout()
            
            }
                
        },

        logout(){
            this.$router.push('/')
            this.$store.commit("user", null)
            this.setCookie("token", null)
        },

        setCookie(cname, cval){
            let now = new Date();
            now.setMonth( now.getYear() + 1 );
            document.cookie = cname+"="+cval+"; expires="+now.toUTCString()+"; path=/";
        },

        getCookie(cname){
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');

            for(var i = 0; i <ca.length; i++){
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },

        setPageTitle(title){
            document.title = title + ' || SantiEmpleo';
        }, 

        replaceBreaks(text){
            if (text == undefined || text == null){
                return null
            }

            return text.replace(/(?:\r\n|\r|\n)/g, '<br>')
        },

        getEditorContent(_id){
            var editor = document.getElementById(_id)

            var iframe = editor.getElementsByTagName('iframe')[0]
            

            var htmlContent =  iframe.contentWindow.document.body.innerHTML

            //console.log(htmlContent, htmlContent, this.logPrefix + '>setEditorContent');
            return htmlContent 

        },

        setEditorContent(_id, _content){
            var editor = document.getElementById(_id)

            var iframe = editor.getElementsByTagName('iframe')[0]

            //console.log(_id, _id, this.logPrefix + '>setEditorContent');
            //console.log(_content, _content, this.logPrefix + '>setEditorContent');
            

            iframe.contentWindow.document.body.innerHTML = _content
        },

        slugify(text) {
            var slug = "";
            // Change to lower case
            var titleLower = text.toLowerCase();
            // Letter "e"
            slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
            // Letter "a"
            slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
            // Letter "o"
            slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
            // Letter "u"
            slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");

            // Letter "i"
            slug = slug.replace(/i|î|ï|í|ī|į|ì/gi, "i");

            // Letter "c"
            slug = slug.replace(/c|ç|ć|č/gi, "c");

            // Letter "oe"
            slug = slug.replace(/oe|œ/gi, "oe");


            // Remove "."
            slug = slug.replace(".", " ");
            slug = slug.replace(".", " ");
            slug = slug.replace(".", " ");
            // Remove "("
            slug = slug.replace("(", " ");
            // Remove ")"
            slug = slug.replace(")", " ");
            // Remove "|"
            slug = slug.replace("|", " ");
            // Remove "-"
            slug = slug.replace("-", " ");
            // Remove "/"
            slug = slug.replace("/", " ");
            // Remove ","
            slug = slug.replace(",", " ");
            // Remove "’"
            slug = slug.replace("’", "");
            // Letter "d"
            slug = slug.replace(/đ/gi, "d");
            // Trim the last whitespace
            slug = slug.replace(/\s*$/g, "");
            // Change whitespace to "-"
            slug = slug.replace(/\s+/g, "-");
            // Remove ":"
            slug = slug.replace(/:/g, "");
            // Remove "'"
            slug = slug.replace(/'/g, "");

            return slug;
        },

        validatePhone(event) {

            if (event.target.value.length != null){
              if ( event.target.value.length != 10  ){
                alert('Numero invalido')
                event.target.value = null
              }
            }
          },
      
        maskPhone(string){
            return string.replace(this.phoneExp, "$1-$2-$3")
        },
    
        cleanUpPhone(phone){
            return phone.replace(/\D/g,'')
        }
        
    }
}