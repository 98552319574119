import Home from '@/pages/Home';
import Contacts from '@/pages/Contacts';
import Contact from '@/pages/Contact';
import Clients from '@/pages/Clients';
import Client from '@/pages/Client';
import JobPostings from '@/pages/JobPostings';
import JobPosting from '@/pages/JobPosting';
import Campaigns from '@/pages/Campaigns';
import Campaign from '@/pages/Campaign';
import Applications from '@/pages/Applications';
import Application from '@/pages/Application';
import SurveyQuestions from '@/pages/SurveyQuestions';
import SurveyQuestion from '@/pages/SurveyQuestion';
import SearchResults from '@/pages/SearchResults';
import Login from '@/pages/Login';


export const routes = [
    // Each route needs a path
    {
        path: '/',
        component: Home,
        name: 'home',
    },

    {
        path: '/login',
        component: Login,
        name: 'login',
    },

    /**CONTACTS */
    {
        path: '/contacts',
        component: Contacts,
        name: 'Contacts',
    },

    {
        path: '/contact',
        component: Contact,
        name: 'ContactNew',
    },

    {
        path: '/contact/:Id',
        component: Contact,
        name: 'Contact'
    },

    /** CLIENTS */
    {
        path: '/clients',
        component: Clients,
        name: 'Clients',
    },

    {
        path: '/client',
        component: Client,
        name: 'ClientNew',
    },

    {
        path: '/client/:Id',
        component: Client,
        name: 'Client'
    },

    /** JOB POSTINGS */
    {
        path: '/jobpostings',
        component: JobPostings,
        name: 'JobPostings',
    },

    {
        path: '/jobposting',
        component: JobPosting,
        name: 'JobPostingNew',
    },

    {
        path: '/jobposting/:Id',
        component: JobPosting,
        name: 'JobPosting'
    },

    /** CAMPAIGN */
    {
        path: '/campaigns',
        component: Campaigns,
        name: 'Campaigns',
    },

    {
        path: '/campaign',
        component: Campaign,
        name: 'CampaignNew',
    },

    {
        path: '/campaign/:Id',
        component: Campaign,
        name: 'Campaign'
    },

    /** APPLICATIONS */
    {
        path: '/applications',
        component: Applications,
        name: 'Applications',
    },
    
    {
        path: '/application/:Id',
        component: Application,
        name: 'Application'
    },

    /** SURVEY QUESTIONS */
    {
        path: '/SurveyQuestions',
        component: SurveyQuestions,
        name: 'SurveyQuestions',
    },
    
    {
        path: '/surveyQuestion/:Id',
        component: SurveyQuestion,
        name: 'SurveyQuestion'
    },

    {
        path: '/surveyQuestion',
        component: SurveyQuestion,
        name: 'surveyQuestionNew',
    },

    
    /** SEARCH RESULTS */
    {
        path: '/searchresults/:textToSearch',
        component: SearchResults,
        name: 'SearchResults'
    }

    
    
];