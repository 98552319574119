<template>
  <div>
    <h1><b-icon-people /> 
      Contactos
      <router-link to="/contact" class="btn btn-primary m-2">
        <b-icon-plus-circle /> 
      </router-link>
    </h1>

    <div class="container" >
      <div class="row my-2">
        <div class="col-sm-8"></div>
        <div class="col-sm-2">Filtrar por estado: </div>
        <div class="col-sm-2">
          <b-form-select
            v-model="applicationStatusFilter"
            class="form-select"
            :options="applicationStatusesFilter"
            @change="getContacts()"
          />
        </div>
      </div>
    </div>

    <div class="container" >
      <div v-for="iLetter in letters" :key="iLetter" class="letter" :class="{'bg-primary text-white': letter == iLetter}" @click="letter=iLetter; getContacts()" >
        {{ iLetter }}
      </div>
    </div>

    <div class="container">
      <div v-for="cnt in contacts" :key="cnt.Id" class="row border-bottom py-3 grid-row">
        <div class="col-sm"> {{cnt.FirstName}} {{cnt.LastName}} </div>
        <div class="col-sm"> {{cnt.Cellphone}} </div>
        <div class="col-sm"> {{optionsSmsConsentLabel(cnt.SmsConsent)}} </div>
        <div class="col-sm"> {{cnt.Status}} </div>
        <div class="col-sm"> {{cnt.CreatedOn}} </div>
        <div class="col-sm"> <router-link :to="'/contact/'+cnt.Id"> <b-icon-eye /> </router-link> </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/mixins/common";
export default {
  data() {
      return {
          logPrefix: "Contacts",
          contacts: null,
          letter: 'A',
          applicationStatusesFilter: [{ value: "all", text: "Todos" }],
          applicationStatusFilter: 'all'
        }
  },
  computed: {
    // there have to be a way to get this array without doing it like this but I don't know it ^^
    letters() {
      let letters = ["All"]
      for(let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {letters.push(String.fromCharCode([i]))}
      return letters
    }
  },

  methods: {
    getContacts() {
      this.contacts = []
      const formData = new FormData()
      formData.append("action", "list")
      formData.append("t", this.getToken())

      let query = 'SELECT * FROM Contacts WHERE RecordStatus="active" '

      if (this.letter != "All"){
        query += ' AND FirstName Like "'+this.letter+'%"  '
      }

      if (this.applicationStatusFilter != 'all'){
        query += " AND Id IN (SELECT a.Contact FROM Applications a WHERE a.Status = '" + this.applicationStatusFilter + "') "
      }

      query += ' ORDER BY FirstName ASC LIMIT 500'

      formData.append("query", query)
      this.axios
        .post(this.$store.state.WS_URL + "/", formData)
        .then((response) => {

          //console.log('data', response.data)

          if (response.status == 200) {

            if (response.data.status == "OK") {
              this.contacts = response.data.records
            } else {
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },
  },
  mounted: function () {
    this.getContacts();

    this.setPageTitle("Contacts")

    this.applicationStatusesFilter = this.applicationStatusesFilter.concat(this.applicationStatus)

  },
  mixins: [common],
};
</script>

<style>
.letter{
  float: left;
  width: calc(100% / 27);
  text-align: center;
  background-color: #efefef;
}
</style>
