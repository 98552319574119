<template>
  <div>
    <h1><b-icon-pencil-square /> 
      Aplicaciones
    </h1>
    
    <div class="container">
      <div v-for="app in applications" :key="app.Id" class="row border-bottom py-3 grid-row">
        <div class="col-sm"> {{app.FirstName}} {{app.LastName}}  </div>
        <div class="col-sm"> {{app.JobPostingName}}  </div>
        <div class="col-sm"> Respuestas ({{app.answers}}) </div>
        <div class="col-sm"> {{app.CreatedOn}} </div>
        <div class="col-sm"> <router-link :to="'/application/'+app.Id"> <b-icon-eye /> </router-link> </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/mixins/common";
export default {
  data() {
      return {
          logPrefix: "Applications",
          applications: null,
          };
  },
  methods: {
    getApplications() {
      const formData = new FormData()
      formData.append("action", "list")
      formData.append("t", this.getToken())
      formData.append("query", 'SELECT * FROM Applications_Basic_Info LIMIT 100')
      this.axios
        .post(this.$store.state.WS_URL + "/", formData)
        .then((response) => {

          //console.log('data', response.data)

          if (response.status == 200) {

            if (response.data.status == "OK") {
              this.applications = response.data.records
            } else {
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    }
  },
    

    mounted: function () {
    this.getApplications()
    this.setPageTitle("Applications")

  },
  mixins: [common],
};
</script>

<style></style>
