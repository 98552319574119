<template>
  <div>
    <section class="vh-100">
  <div class="container-fluid h-custom">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-md-9 col-lg-6 col-xl-5">
        <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp" class="img-fluid"
          alt="Sample image">
      </div>
      <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">

        <img :src="$store.state.IMG_URL+'/logo.png'" 
          class="mx-auto d-block my-5" 
          style="max-width: 50px;" /> 
        

        <form>
          <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-start d-none">
            <p class="lead fw-normal mb-0 me-3">Sign in with</p>
            <button type="button" class="btn btn-primary btn-floating mx-1">
              <i class="fab fa-facebook-f"></i>
            </button>

            <button type="button" class="btn btn-primary btn-floating mx-1">
              <i class="fab fa-twitter"></i>
            </button>

            <button type="button" class="btn btn-primary btn-floating mx-1">
              <i class="fab fa-linkedin-in"></i>
            </button>
          </div>

          <div class="divider d-flex align-items-center my-4 d-none">
            <p class="text-center fw-bold mx-3 mb-0">Or</p>
          </div>

          <!-- Email input -->
          <div class="form-outline mb-4">
            <input type="email" id="email" v-model="email" class="form-control form-control-lg"
              placeholder="usuario" />
            <label class="form-label" for="email">Email address</label>
          </div>

          <!-- Password input -->
          <div class="form-outline mb-3">
            <input type="password" id="password" v-model="password" class="form-control form-control-lg"
             v-on:keyup.enter="login"
              placeholder="clave" />
            <label class="form-label" for="password">Password</label>
          </div>

          <div class="d-none d-flex justify-content-between align-items-center">
            <!-- Checkbox -->
            <div class="form-check mb-0">
              <input class="form-check-input me-2" type="checkbox" value="" id="form2Example3" />
              <label class="form-check-label" for="form2Example3">
                Remember me
              </label>
            </div>
            <a href="#!" class="text-body">Forgot password?</a>
          </div>

          <div class="text-center text-lg-start mt-4 pt-2">
            <button type="button" class="btn btn-primary btn-lg my-2"
              style="padding-left: 2.5rem; padding-right: 2.5rem;"
              @click="login">Login</button>
            <p class="small fw-bold mt-2 pt-1 mb-0 d-none">Don't have an account? <a href="#!"
                class="link-danger">Register</a></p>
          </div>

        </form>
      </div>
    </div>
  </div>
  <div id="footer" class="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">
    <!-- Copyright -->
    <div class="text-white mb-3 mb-md-0">
      Copyright © {{this.thisYear}}. All rights reserved. v3.2
    </div>
    <!-- Copyright -->

    <!-- Right -->
    <div>
      <a href="#!" class="text-white me-4">
        <i class="fab fa-facebook-f"></i>
      </a>
      <a href="#!" class="text-white me-4">
        <i class="fab fa-twitter"></i>
      </a>
      <a href="#!" class="text-white me-4">
        <i class="fab fa-google"></i>
      </a>
      <a href="#!" class="text-white">
        <i class="fab fa-linkedin-in"></i>
      </a>
    </div>
    <!-- Right -->
  </div>
</section>
  </div>
</template>

<script>
import common from "@/mixins/common";

export default {

    data() {
        return {
            logPrefix: "Login",
            email: '', 
            password: '',
            thisYear: 2020
          };
    },
    methods: {
      login() {
        this.setCookie("token", null)

        const formData = new FormData();
        formData.append('action', 'login');
        formData.append('u', this.email);
        formData.append('p', this.password);
        this.axios.post(this.$store.state.WS_URL + '/', formData).then(response => {

          if (response.status == 200) {
              if (response.data.status == 'OK'){
                this.$store.commit("lastTokenRefresh", Date.now())
                this.$store.commit("user", response.data.user)
                this.setCookie("token", response.data.user.token)
              }else{
                console.log(response.data)
                alert('Credenciales incorrectas')
              }
              
          } else {
              console.log(response);
          }
        });
            
    },
  },

  mounted: function () {
    this.setPageTitle("Login")

    //Force a token refresh if any
    this.$store.commit("lastTokenRefresh", (Date.now() - 240001))
    this.$store.commit("lastUserActivity")
    this.refreshToken()

    this.thisYear = new Date().getFullYear()

  },

  mixins: [common],

}
</script>

<style scoped>
 
@media (min-width: 992px) { 
  #footer{
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

</style>
