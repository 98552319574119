<template>
  <div>
    <div class="container">
      <h1 v-if="Id"><b-icon-award /> Cliente</h1>
      <h1 v-else><b-icon-plus-circle /> Nuevo Cliente</h1>
    </div>

    <!--<ACTIONS>-->
    <div class="container my-3">
      <div v-if="mode == 'view'">

        <button class="btn btn-primary float-right" @click="mode = 'edit'">
          <b-icon-pencil />
        </button>

        <button class="btn btn-danger float-right mx-2" @click="deleteClient">
          <b-icon-file-earmark-minus-fill />
        </button>
      </div>
      <div v-else>
        <button class="btn btn-action float-right mx-2" @click="saveClient">
          <b-icon-file-check />
        </button>
        <button class="btn btn-secondary float-right mx-2" @click="cancel">
          <b-icon-file-x />
        </button>
      </div>
    </div>
    <!--</ACTIONS>-->

    <div style="clear: both"></div>

    <!--<FORM_DATA>-->
    <div class="container">
      <div class="row">
        <div class="col-sm my-3">
          <input
            :disabled="mode == 'view'"
            type="text"
            class="form-control"
            placeholder="Primer Nombre"
            v-model="clientData.Name"
          />
        </div>
        
      </div>
    </div>
    <!--</FORM_DATA>-->

    
    <!--<SYSTEM_PROPS>-->
    <div v-if="Id" class="container border-top border-bottom py-3 text-grey-900" >
      <h4>Propiedades del sistema</h4>

      <div class="row">
        <div class="col-sm-2 font-weight-bold">Id</div>
        <div class="col-sm">
          <span v-html="this.Id" />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2 font-weight-bold">Creacion</div>
        <div class="col-sm">
          <span v-html="clientData.CreatedOn" />
        </div>

        <div class="col-sm-2 font-weight-bold">Creado por</div>
        <div class="col-sm">
          <span v-html="getUsername(clientData.CreatedBy)" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 font-weight-bold">Ultima modificacion</div>
        <div class="col-sm">
          <span v-html="clientData.ModifiedOn" />
        </div>

        <div class="col-sm-2 font-weight-bold">Modificado por</div>
        <div class="col-sm">
          <span v-html="getUsername(clientData.ModifiedBy)" />
        </div>
      </div>
    </div>
    <!--</SYSTEM_PROPS>-->
  </div>
</template>

<script>
import common from "@/mixins/common";

export default {
  data() {
    return {
      logPrefix: "NewClient",
      mode: "view",
      
      Id: null,
      clientData: {
        Name: ""
      }
      
    };
  },

  

  methods: {
    saveClient() {
      const clientData = new FormData();
      clientData.append("action", "upsert");
      clientData.append("t", this.getToken());
      clientData.append("table", "Clients");
      clientData.append("Id", this.Id);
      clientData.append("fields", JSON.stringify(this.clientData));

      this.axios
        .post(this.$store.state.WS_URL + "/", clientData)
        .then((response) => {
          console.log(response.data);

          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.Id = response.data.Id;
              this.mode = 'view'
            } else {
              
              alert(
                "Problemas al guardar Cliente: " + response.data.message
              )
              
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },

    deleteClient() {
      if (confirm('Seguro que desea borrar Cliente?')) {
        this.clientData.RecordStatus = 'deleted'
        this.clientData.Name = this.clientData.Name + '-DELETED-' + this.clientData.Id
        this.saveClient()
        this.$router.push('/Clients')
      }
    },

    getClient() {
      const clientData = new FormData();
      clientData.append("action", "list");
      clientData.append("t", this.getToken());
      clientData.append("query", "SELECT * FROM Clients WHERE Id = '" + this.Id + "'");
      this.axios
        .post(this.$store.state.WS_URL + "/", clientData)
        .then((response) => {
          //console.log(response.status, response.data);
          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.clientData = response.data.records[0];
              this.setPageTitle( this.clientData.Name )

            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });
    },

    
    cancel(){
      if (this.mode == 'edit'){
        this.mode = 'view'
      }else if (this.mode == 'new'){
        this.$router.push('/Clients')
      }
    },


  },

  mounted: function () {
    if (this.$route.params.Id != undefined) {
      this.Id = this.$route.params.Id
      this.getClient()
    } else {
      this.mode = "new"
      this.setPageTitle("New Client")
    }
  },

  mixins: [common],
};
</script>

<style></style>
