<template>
  <div>
    <h1>HOME</h1>

    <div class="container">
      <div class="row">
        <div class="col-sm-4">
          <PlazasPendientesChart />
        </div>
        <div class="col-sm">
          <div class="row">
            <div class="col-sm my-2">
              <StatsWidget
                title="Aplicaciones recientes"
                :value="recentApplications.length"
                objectName="Applications"
              />
            </div>
            <div class="col-sm my-2">
              <StatsWidget
                title="Plazas activas"
                :value="activeJobPostings.length"
                objectName="JobPostings"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm my-2">
              <StatsWidget
                title="Contactos subscritos"
                :value="subscibedContacts.length"
                objectName="Contacts"
              />
            </div>
            <div class="col-sm my-2">
              <StatsWidget
                title="Contactos"
                :value="allContacts.length"
                objectName="Contacts"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm my-2">
              <StatsWidget
                title="Mensajes enviados este mes"
                :value="smsSentThisMonth.length"
              />
            </div>
            <div class="col-sm my-2">
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/mixins/common";
import PlazasPendientesChart from "@/components/charts/PlazasPendientesChart";
import StatsWidget from "@/components/stats/StatsWidget";

export default {
  components: { PlazasPendientesChart, StatsWidget },

  mixins: [common],

  data() {
    return {
      logPrefix: "Home",
      recentApplications: 0,
      activeJobPostings: 0,
      subscibedContacts: 0,
      allContacts: 0,
      smsSentThisMonth: 0,
    };
  },
  methods: {
    ini() {
      const formData = new FormData();
      formData.append("action", "list");
      formData.append("t", this.getToken());

      formData.append(
        "query",
        "SELECT * FROM Applications WHERE CreatedOn >= DATE(NOW()) - INTERVAL 7 DAY"
      );
      this.axios
        .post(this.$store.state.WS_URL + "/", formData)
        .then((response) => {
          //console.log('data', response.data)

          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.recentApplications = response.data.records;
            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });

      formData.append(
        "query",
        'SELECT * FROM JobPostings WHERE Status = "open"'
      );
      this.axios
        .post(this.$store.state.WS_URL + "/", formData)
        .then((response) => {
          //console.log('data', response.data)

          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.activeJobPostings = response.data.records;
            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });

      formData.append(
        "query",
        'SELECT * FROM Contacts WHERE SmsConsent = "subscribed"'
      );
      this.axios
        .post(this.$store.state.WS_URL + "/", formData)
        .then((response) => {
          //console.log('data', response.data)

          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.subscibedContacts = response.data.records;
            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });

      formData.append(
        "query",
        'SELECT * FROM Contacts '
      );
      this.axios
        .post(this.$store.state.WS_URL + "/", formData)
        .then((response) => {
          //console.log('data', response.data)

          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.allContacts = response.data.records;
            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });

      const d = new Date()
      let month = d.getMonth()
      let year = d.getFullYear()

      let query = 'SELECT * FROM CampaignRecipients WHERE Status != "pending" AND YEAR(CreatedOn) = '+year+' AND MONTH(CreatedOn) =  ' + month
      console.log(month, year, query)


      formData.append(
        "query",
        query
      );
      this.axios
        .post(this.$store.state.WS_URL + "/", formData)
        .then((response) => {
          //console.log('data', response.data)

          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.smsSentThisMonth = response.data.records;
            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });

    },
  },
  mounted: function () {
    this.setPageTitle("HOME");
    this.ini();
  },
};
</script>

<style></style>
