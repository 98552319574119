<template>
  <div>
    <div class="container">
       <h1>Resultados para: <b v-html="textToSearch" /></h1> 
    </div>
    <div class="container">
      <div v-for="searchResult in searchResults" :key="searchResult.Id" class="row border-bottom py-3 grid-row">
        <div class="col"> {{searchResult.RecordContent}} </div>
        <div class="col"> {{searchResult.RecordType}} </div>
        <div class="col"> <router-link :to="{ name: searchResult.RecordType, params: { Id: searchResult.RecordId }}"> <b-icon-eye /> </router-link> </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/mixins/common";

export default {
    data() {
      return {
            logPrefix: "Header",
            textToSearch: null,
            searchResults: [],
        };
    },

    watch: {
        $route (to, from){
            console.log('CHANGE', to, from)
            this.getSearchResults()
        }
    },
  
    methods: {
        getSearchResults() {
            if (this.$route.params.textToSearch != undefined) {
                this.textToSearch = this.$route.params.textToSearch
            } 
            
            this.searchResults = []
            const formData = new FormData()
            formData.append("action", "list")
            formData.append("t", this.getToken())
            formData.append("query", 'SELECT * FROM Search_All WHERE RecordContent LIKE "%'+this.textToSearch+'%"  OR AdditionalContent = "%'+this.textToSearch+'%" ')
            this.axios
                .post(this.$store.state.WS_URL + "/", formData)
                .then((response) => {

                //console.log('data', response.data)

                if (response.status == 200) {

                    if (response.data.status == "OK") {
                    this.searchResults = response.data.records
                    } else {
                    this.handleWsErr(response.data)
                    }
                } else {
                    console.log(response);
                }
                });
        },
    },

    mounted: function () {

        this.getSearchResults()

        this.setPageTitle("Search Results")
    },

    mixins: [common],

}
</script>

<style>

</style>