<template>
  <div>
    <h1><b-icon-award />  
      Preguntas
      <router-link to="/surveyQuestion" class="btn btn-primary m-2">
        <b-icon-plus-circle />
      </router-link>
    </h1>
    <div class="container">
      <div class="row border-bottom py-3 grid-row bg-secondary font-weight-bold">
        <div class="col link" @click="getSurveyQuestions('Question')"> Pregunta </div>
        <div class="col link" @click="getSurveyQuestions('Type')"> Typo </div>
        <div class="col">Opciones</div>
        <div class="col"> </div>
      </div>

      <div v-for="iQuestion in surveySurveyQuestions" :key="iQuestion.Id" class="row border-bottom py-3 grid-row">
        <div class="col"> {{iQuestion.Question}} </div>
        <div class="col"> {{iQuestion.Type}} </div>
        <div class="col" v-html="replaceBreaks(iQuestion.Options)"> </div>
        <div class="col"> <router-link :to="'/surveyQuestion/'+iQuestion.Id"> <b-icon-eye /> </router-link> </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/mixins/common";
export default {
  data() {
      return {
          logPrefix: "SurveyQuestions",
          surveySurveyQuestions: [],
          lastSort: "ASC"
      };
  },
  methods: {
    getSurveyQuestions(sortByField) {

      this.surveySurveyQuestions= []

      if (this.lastSort == "ASC"){
        this.lastSort = "DESC"
      } else {
        this.lastSort = "ASC"
      }
      
      const contactData = new FormData();
      contactData.append("action", "list");
      contactData.append("t", this.getToken());
      contactData.append("query", "SELECT * FROM SurveyQuestions WHERE RecordStatus = 'active' ORDER BY " +sortByField+' '+this.lastSort );
      this.axios
        .post(this.$store.state.WS_URL + "/", contactData)
        .then((response) => {
          //console.log(response.status, response.data, this.logPrefix + '>getSurveyQuestions');
          if (response.status == 200) {
            if (response.data.status == "OK") {
              response.data.records.forEach( (surveyQuestion) => {
                  surveyQuestion.value = surveyQuestion.Id
                  surveyQuestion.text = surveyQuestion.Question
                  surveyQuestion.disabled = false
                  this.surveySurveyQuestions.push(surveyQuestion)
              })
            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });
    },
  },
  mounted: function () {
    this.getSurveyQuestions("CreatedOn")
    this.setPageTitle("Survey Questions")

  },
  mixins: [common],
};
</script>

<style></style>
