<template>
  <div>
    <div class="container border rouded bg-primary p-0">
      <div class="bg-white p-3" style="margin-left: 5px;">
        <router-link :to="{name: objectName}" class="link"> <h4 v-html="title" class="text-primary" /> </router-link>
        <h3 v-html="value" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Stat Title",
    },
    value: {
      //type: Object, //Can take any type
      default: null,
    },
    objectName: {
      type: String,
      default: "#",
    },
  },
};
</script>

<style></style>
