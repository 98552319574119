<template>
  <div>
    <h1><b-icon-chat-left-text /> 
      Campañas
      <router-link to="/Campaign" class="btn btn-primary m-2">
        <b-icon-plus-circle /> 
      </router-link>
    </h1>
    
    <div class="container">
      <div v-for="camp in campaigns" :key="camp.Id" class="row border-bottom py-3 grid-row">
        <div class="col-sm"> {{camp.Name}}  </div>
        <div class="col-sm"> {{camp.jobPostingName}} </div>
        <div class="col-sm"> {{camp.SentOn}} </div>
        <div class="col-sm"> <span v-if="camp.SentOn" v-html="camp.Recipients" /> </div>
        <div class="col-sm"> <router-link :to="'/Campaign/'+camp.Id"> <b-icon-eye /> </router-link> </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/mixins/common";
export default {
  data() {
      return {
          logPrefix: "Campaigns",
          campaigns: null,
          };
  },
  methods: {
    getCampaigns() {
      const formData = new FormData()
      formData.append("action", "list")
      formData.append("t", this.getToken())
      formData.append("query", 'SELECT * FROM CampaignsInfo ')
      this.axios
        .post(this.$store.state.WS_URL + "/", formData)
        .then((response) => {

          //console.log('data', response.data)

          if (response.status == 200) {

            if (response.data.status == "OK") {
              this.campaigns = response.data.records
            } else {
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },
  },
  mounted: function () {
    this.getCampaigns()
    this.setPageTitle("Campaigns")

  },
  mixins: [common],
};
</script>

<style></style>
