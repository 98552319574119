<template>
  <div>
    <div class="container" style="max-width:100%" v-if="$store.getters.isLogged">
      <div class="row">
        <div class="col-2 bg-primary overflow-auto" style="height: 100vh;">
          <Sidebar />
        </div>

        <div class="col-10 p-0 bg-grey-200" style="height: 100vh;">
          <Header class="p-2 pt-4 bg-white " />
          <router-view class="p-2 overflow-auto" style="height: calc(100% - 150px)"/>
          <Footer class="p-2 bg-white " />
        </div>
      </div>
    </div>

    <div v-else>
      <Login />
    </div>
    
  </div>
</template>

<script>
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import Sidebar from '@/components/layout/Sidebar.vue'
import Login from '@/pages/Login.vue'

export default {
  name: 'App',


  data() {
    return {
      logSource: "App",
    };
  },


  created: function () {
    //let logTrace = this.logSource + ".created"
    //console.log('INI', logTrace)
  },

  mounted() {

    this.$nextTick(() => {
      var link = document.querySelector("link[rel~='icon']");
      link.href = this.$store.state.IMG_URL+'/logo.png';
    });
  },

  components: {
    Header, Footer, Sidebar, Login
  }
}
</script>

