<template>
  <div>
    <h1><b-icon-file-earmark-ruled /> 
      Plazas
      <router-link to="/JobPosting" class="btn btn-primary m-2">
        <b-icon-plus-circle /> 
      </router-link>
    </h1>
    
    <div class="container">
      <div class="row border-bottom py-3 grid-row bg-secondary font-weight-bold">
      <div class="col link" @click="getJobPostings('name')"> Titulo </div>
        <div class="col link" @click="getJobPostings('status')"> Estado </div>
        <div class="col"> Preguntas </div>
        <div class="col link" @click="getJobPostings('language')"> Idioma </div>
        <div class="col link" @click="getJobPostings('CreatedOn')"> Fecha </div>
        <div class="col">  </div>
      </div>
      <div v-for="jobPosting in jobPostings" :key="jobPosting.Id" class="row border-bottom py-3 grid-row">
        <div class="col"> {{jobPosting.Name}} </div>
        <div class="col"> {{jobPosting.Status}} </div>
        <div class="col"> {{jobPosting.jpSurveyQuestions}} </div>
        <div class="col"> {{jobPosting.Language}} </div>
        <div class="col"> {{jobPosting.CreatedOn}} </div>
        <div class="col"> <router-link :to="'/JobPosting/'+jobPosting.Id"> <b-icon-eye /> </router-link> </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/mixins/common";
export default {
  data() {
      return {
            logPrefix: "JobPostings",
            jobPostings: null,
            lastSort: "ASC"
          };
  },
  methods: {
    getJobPostings(sortByField) {
      console.log('INI')
      
      
      if (this.lastSort == "ASC"){
        this.lastSort = "DESC"
      } else {
        this.lastSort = "ASC"
      }

      let query = 'SELECT jp.*,  '
      query += ' (SELECT Count(1) FROM JobPostingSurveyQuestions sq WHERE sq.JobPosting = jp.Id AND jp.RecordStatus = "active" ) jpSurveyQuestions '
      query += ' FROM JobPostings jp WHERE RecordStatus!="deleted" '
      query += ' Order by '+sortByField+' '+this.lastSort
      const formData = new FormData()
      formData.append("action", "list")
      formData.append("t", this.getToken())
      formData.append("query", query)

      console.log('query', query)

      this.axios
        .post(this.$store.state.WS_URL + "/", formData)
        .then((response) => {

          //console.log('data', response.data)

          if (response.status == 200) {

            if (response.data.status == "OK") {
              this.jobPostings = response.data.records
            } else {
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },
  },
  mounted: function () {
    this.getJobPostings('CreatedOn')
    this.setPageTitle("Job Postings")

  },
  mixins: [common],
};
</script>

<style></style>
