<template>
  <div>
      <div class="container">
        <p class="mb-0">© {{new Date().getFullYear()}} SantiEmpleo. Developed By <a href="https://franciscog.ca/" target="_blank">FranciscoG</a>.</p>   
      </div> 
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>