<template>
  <div>
    <div class="container">
      <div v-if="Id" class="row">
        <div class="col-sm-1 text-center">
          <img :src="$store.state.PUBLIC_SITE_URL + '/se-assets/icons/JobsIcons/' + jobPostingData.JobIcon" alt="" class="mx-auto d-block img-fluid"/> 
          <div v-if="mode != 'view'" @click="showJobsIcons=true" class="link text-primary" >Cambiar</div>
        </div>
        <div class="col-sm text-left">
          <h1>Plaza <a :href="$store.state.PUBLIC_SITE_URL + '/job/' + jobPostingData.Slug" target="_blank"><b-icon-link /></a></h1>
        </div>
      </div>
      <h1 v-else><b-icon-plus-circle /> Nueva Plaza</h1>
    </div>

    <!--<ACTIONS>-->
    <div class="container my-3">
      <div v-if="mode == 'view'">

        <button class="btn btn-primary float-right" @click="mode = 'edit'">
          <b-icon-pencil />
        </button>

        <button class="btn btn-danger float-right mx-2" @click="deleteJobPosting">
          <b-icon-file-earmark-minus-fill />
        </button>
      </div>
      <div v-else>
        <button class="btn btn-action float-right mx-2" @click="saveJobPosting">
          <b-icon-file-check />
        </button>
        <button class="btn btn-secondary float-right mx-2" @click="cancel">
          <b-icon-file-x />
        </button>
      </div>
    </div>
    <!--</ACTIONS>-->

    <div style="clear: both"></div>

    <!--<FORM_DATA>-->
    <div class="container mb-5">
      <div class="row">
        <div class="col-sm my-3">
          <input
            :disabled="mode == 'view'"
            type="text"
            class="form-control"
            placeholder="Nombre"
            v-model="jobPostingData.Name"
          />
        </div>

        <div class="col-sm my-3">
          <b-form-select
            v-model="jobPostingData.Status"
            class="form-select"
            :options="optionsStatus"
            :disabled="mode == 'view'"
          />
        </div>

        <div class="col-sm my-3">
          <input
            :disabled="mode == 'view'"
            type="number"
            class="form-control"
            placeholder="Plazas disponibles"
            v-model="jobPostingData.Openings"
          />
        </div>
        
      </div>

      <div class="row">
        <div class="col-sm my-3">
          <input
            :disabled="mode == 'view'"
            type="text"
            class="form-control"
            placeholder="Lugar"
            v-model="jobPostingData.Location"
          />
        </div>

        <div class="col-sm my-3">
          <input
            :disabled="mode == 'view'"
            type="text"
            class="form-control"
            placeholder="Detalles de Transporte"
            v-model="jobPostingData.Transportation"
          />
        </div>

        <div class="col-sm my-3">
          <input
            :disabled="mode == 'view'"
            type="text"
            class="form-control"
            placeholder="Categorias. e.g. Trabajo de bodega, Limpieza, Labores generales, Motorista, etc."
            v-model="jobPostingData.Category"
          />
        </div>
        
      </div>

      <div class="row my-3">
        <!--Address-->
        <div class="col-sm-8">
          <template v-if="mode == 'view'">
            <b>Address:</b>
            <template v-if="jobPostingData.GoogleAddress">
              {{ jobPostingData.GoogleAddress.formatted_address }}
              <a :href="jobPostingData.GoogleAddress.url" target="_blank"><b-icon-map /></a>
            </template>
          </template>
          <template v-else >
            <!-- https://www.digitalocean.com/community/tutorials/vuejs-vue-google-maps -->
            <GmapAutocomplete
              class="form-control"
              id="GoogleAutoCompleteInput"
              :options="googleMapAutocompleteOptions"
              @place_changed="setPlace"
            />
          
            <div v-if="googleAddress" class="mt-2">
              <b>Address:</b>
              {{ googleAddress.formatted_address }}
              <a :href="googleAddress.url" target="_blank"><b-icon-link /></a>
            </div>
          </template>
        
        </div>
         <!--/Address-->

         <div class="col-sm">
            <select v-model="jobPostingData.Language" class="form-select" :disabled="mode == 'view'" @change="getSurveyQuestions()" >
              <option value="Spanish">Spanish</option>
              <option value="English">English</option>
            </select>
         </div>
      </div>

      <div class="row">
        <div class="col-sm">

          <div id="jobPostingDescription" :class="{'d-none': mode == 'view'}">
            <Vueditor style="height: 350px;"/>
          </div>
          <div
              class="form-control my-2"
              v-if="mode == 'view'"
              v-html="jobPostingData.Description"
            />

            
            
        </div>
      </div>
    </div>
    <!--</FORM_DATA>-->
    
    <div style="clear:both"></div>


    <!--<TABS>-->
    <div class="container my-2" v-if="Id">
      <div class="row">
        <b-tabs content-class="mt-3">
          <b-tab :title="'Preguntas (' + jobPostingSurveyQuestions.length + ')'" active>
            
            <!--<JOB_POSTING_QUESTIONS>-->
            <div class="container">
              <div v-for="jobPostingSurveyQuestion in jobPostingSurveyQuestions" :key="jobPostingSurveyQuestion.Id" :id="jobPostingSurveyQuestion.Id" class="row border-bottom py-3 grid-row">
                <div class="col-sm-9"> 

                    {{jobPostingSurveyQuestion.SurveyQuestionOrder}}) 
                      <b v-html="getSurveyQuestion(jobPostingSurveyQuestion.SurveyQuestion).Question" /> 
                      <b-icon-pencil class="link" @click="editJobPostingSurveyQuestion(jobPostingSurveyQuestion)" />  

                    <div class="row">
                      <div class="col-sm">
                        <b-form-select
                          v-model="getSurveyQuestion(jobPostingSurveyQuestion.SurveyQuestion).Type"
                          class="form-select my-2"
                          disabled
                          :options="surveyQuestionTypes"
                        />
                      </div>
                      
                      <div class="col-sm">
                        <b-form-select
                          v-model="jobPostingSurveyQuestion.Status"
                          class="form-select my-2"
                          disabled
                          :options="jobPostingSurveyQuestionStatus"
                        />
                      </div>
                    </div>
                    
                    <div class="row">
                      <div class="col" v-html="replaceBreaks(getSurveyQuestion(jobPostingSurveyQuestion.SurveyQuestion).Options)" />
                       
                    </div>
                     
                </div>
                <div class="col-sm"> 
                  <span v-html="getUsername(jobPostingSurveyQuestion.CreatedBy)" />
                  <br>
                  {{jobPostingSurveyQuestion.CreatedOn}} 
                </div>
              </div>
            </div>
            <!--</JOB_POSTING_QUESTIONS>-->


            <!--<ADD_QUESTIONS>-->
            <div class="container py-3">
              <div class="row" id="new-surveyQuestion">
                
                <div class="col-sm-2">
                  <b-form-select
                    v-model="surveyQuestionId"
                    @change="selectSurveyQuestion"
                    class="form-select my-2"
                    :options="surveySurveyQuestions"
                  />
                </div>
                
                <div class="col-sm-2">
                  <b-form-select
                    v-model="surveyQuestionData.Type"
                    class="form-select my-2"
                    :disabled="this.surveyQuestionId!=null"
                    :options="surveyQuestionTypes"
                  />
                </div>
                
                <div class="col-sm">
                  <input
                    type="text"
                    :disabled="this.surveyQuestionId!=null"
                    :class="{'d-none': this.surveyQuestionId!=null}"
                    class="form-control my-2"
                    placeholder="Pregunta"
                    v-model="surveyQuestionData.Question"
                  />

                  <textarea
                    type="text"
                    rows="3"
                    :disabled="this.surveyQuestionId!=null"
                    class="form-control my-2"
                    placeholder="Opciones (Una por linea)"
                    v-model="surveyQuestionData.Options"
                    v-if="surveyQuestionData.Type=='select'"
                  />
                </div>

                <div class="col-sm-2">
                  <input
                    type="text"
                    class="form-control my-2"
                    placeholder="Orden"
                    v-model="jobPostingSurveyQuestionData.SurveyQuestionOrder"
                  />

                  <b-form-select
                    v-model="jobPostingSurveyQuestionData.Status"
                    class="form-select my-2"
                    :options="jobPostingSurveyQuestionStatus"
                  />
                  
                </div>

                <div class="col-sm-1">
                  <button class="btn btn-primary my-2 float-right" @click="newSurveyQuestion">
                    <b-icon-plus-circle />
                  </button>
                </div>

              </div>
            </div>
            <!--</ADD_QUESTIONS>-->

            


          </b-tab>
          <b-tab :title="'Aplicaciones (' + applications.length + ')'">
            <div v-for="app in applications" :key="app.Id" class="row border-bottom py-3 grid-row">
              <div class="col"> {{app.FirstName}}  </div>
              <div class="col"> {{app.LastName}} </div>
              <div class="col"> Respuestas ({{app.answers}}) </div>
              <div class="col"> {{app.CreatedOn}} </div>
              <div class="col"> <router-link :to="'/application/'+app.Id"> <b-icon-eye /> </router-link> </div>
            </div>
          </b-tab>
          <b-tab :title="'Campañas (' + campaigns.length + ')'">
            <div v-for="camp in campaigns" :key="camp.Id" class="row border-bottom py-3 grid-row">
              <div class="col"> {{camp.Name}}  </div>
              <div class="col"> {{camp.jobPostingName}} </div>
              <div class="col"> {{camp.SentOn}} </div>
              <div class="col"> <router-link :to="'/Campaign/'+camp.Id"> <b-icon-eye /> </router-link> </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>

    </div>
    <!--</TABS>-->

    
    <!--<SYSTEM_PROPS>-->
    <div v-if="Id" class="container border-top border-bottom py-3 text-grey-900" >
      <h4>Propiedades del sistema</h4>

      <div class="row">
        <div class="col-sm-2 font-weight-bold">Id</div>
        <div class="col-sm">
          <span v-html="this.Id" />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2 font-weight-bold">Creacion</div>
        <div class="col-sm">
          <span v-html="jobPostingData.CreatedOn" />
        </div>

        <div class="col-sm-2 font-weight-bold">Creado por</div>
        <div class="col-sm">
          <span v-html="getUsername(jobPostingData.CreatedBy)" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 font-weight-bold">Ultima modificacion</div>
        <div class="col-sm">
          <span v-html="jobPostingData.ModifiedOn" />
        </div>

        <div class="col-sm-2 font-weight-bold">Modificado por</div>
        <div class="col-sm">
          <span v-html="getUsername(jobPostingData.ModifiedBy)" />
        </div>
      </div>
    </div>
    <!--</SYSTEM_PROPS>-->

    <!--<JOB_ICONS>-->
      <div id="jobIcons" v-if="showJobsIcons">
        <div class="container-fluid m-5 bg-white p-5">
          <div class="row"> <h3>Iconos disponibles</h3> </div>
          <div class="row">
            <div v-for="iJobIcon in jobsIcons" :key="iJobIcon.Id" :id="iJobIcon.Id" class="col-sm-1 link">
              <img :src="$store.state.PUBLIC_SITE_URL + '/se-assets/icons/JobsIcons/' + iJobIcon" alt="" 
                style="width: 100%;" class="jobIcon"
                @click="jobPostingData.JobIcon=iJobIcon; showJobsIcons=false;" /> 
            </div>
             
          </div>
        </div>
      </div>
    <!--</JOB_ICONS>-->


  </div>
</template>

<script>
import common from "@/mixins/common";

export default {
  data() {
    return {
      logPrefix: "JobPosting",
      mode: "view",

      googleAddress: null,
      googleMapAutocompleteOptions: {
        componentRestrictions: { country: "us" }, //"ca" for Canada
        fields: ["name", "formatted_address","url", "geometry", "address_components"],
      },
      
      Id: null,
      jobPostingData: {
        Name: null,
        Status: 'closed'
      },

      showJobsIcons: false,
      jobsIcons: [],

      optionsStatus: [
        { value: "open", text: "Abierta" },
        { value: "closed", text: "Cerrada" },
      ],

      surveyQuestionId: null,
      jobPostingSurveyQuestionId: null,

      surveySurveyQuestions: [],
      jobPostingSurveyQuestions: [],
      applications: [],
      campaigns: [],

      surveyQuestionData: {
        Type: 'text',
        Question: null,
        Options: null,
      },
      jobPostingSurveyQuestionData: {
        SurveyQuestionOrder: 99,
        Status: 'active',
      },

      jobPostingSurveyQuestionStatus: [
        { value: "active", text: "Activa" },
        { value: "inactive", text: "Inactiva" },
      ],
      
    };
  },


  methods: {
    setPlace(place) {
      this.googleAddress = place
      document.getElementById('GoogleAutoCompleteInput').value = ''
      this.$forceUpdate();
    },

    saveJobPosting() {
      const jobPostingFormData = new FormData();
      jobPostingFormData.append("action", "upsert");
      jobPostingFormData.append("t", this.getToken());
      jobPostingFormData.append("table", "JobPostings");
      jobPostingFormData.append("Id", this.Id);
      this.jobPostingData.Description = this.getEditorContent('jobPostingDescription')

      let jobPostingName = this.jobPostingData.Name.replace("$", "")
      .replace("'", "")
      .replace("-", "")
      .replace("&", "")
      .replace("#", "")
      .replace(",", "")
      .replace(".", "")

      let r = (Math.random() + 1).toString(36).substring(7);
      jobPostingName += "-" + r

      this.jobPostingData.Slug = this.slugify(jobPostingName)
      this.jobPostingData.GoogleAddress = JSON.stringify(this.googleAddress);
      
      jobPostingFormData.append("fields", JSON.stringify(this.jobPostingData));

      this.axios
        .post(this.$store.state.WS_URL + "/", jobPostingFormData)
        .then((response) => {

          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.Id = response.data.Id
              this.mode = 'view'
              this.getJobPosting()
            } else {
              
              alert(
                "Problemas al guardar Plaza: " + response.data.message
              )
              
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },

    deleteJobPosting() {
      if (confirm('Seguro que desea borrar Plaza?')) {
        this.jobPostingData.RecordStatus = 'deleted'
        this.jobPostingData.Name = this.jobPostingData.Name + '-DELETED-' + this.jobPostingData.Id
        this.saveJobPosting()
        this.$router.push('/JobPostings')
      }
    },

    getJobPosting() {
      const jobPostingData = new FormData();
      jobPostingData.append("action", "list");
      jobPostingData.append("t", this.getToken());
      jobPostingData.append("query", "SELECT * FROM JobPostings WHERE Id = '" + this.Id + "'");
      this.axios
        .post(this.$store.state.WS_URL + "/", jobPostingData)
        .then((response) => {
          //console.log('response.data', response.data, this.logPrefix + '>getJobPosting');
          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.jobPostingData = response.data.records[0]

              if (this.jobPostingData.GoogleAddress){
                this.jobPostingData.GoogleAddress = JSON.parse(this.jobPostingData.GoogleAddress)
                this.googleAddress = this.jobPostingData.GoogleAddress
              }
              
              if (!this.jobPostingData.JobIcon){
                this.jobPostingData.JobIcon = 'default.png'
              }

              this.getSurveyQuestions()
              this.getApplications()
              this.getCampaigns()
              this.setPageTitle( this.jobPostingData.Name )
              this.setEditorContent('jobPostingDescription', this.jobPostingData.Description)

            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });
    },

    getJobsIcons(){
      const axios = require('axios');

      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: this.$store.state.PUBLIC_SITE_URL + '/se-assets/icons/JobsIcons/xJobsIconsList.php',
        headers: { }
      };

      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        this.jobsIcons = response.data
      })
      .catch((error) => {
        console.log(error);
      });

    },

    newSurveyQuestion(){
      if (this.surveyQuestionId!=null){
        this.saveJobPostingSurveyQuestion()
      }else{
        this.saveSurveyQuestion()
      }
    },

    saveSurveyQuestion() {
      const surveyQuestionFormData = new FormData()
      surveyQuestionFormData.append("action", "upsert")
      surveyQuestionFormData.append("t", this.getToken())
      surveyQuestionFormData.append("table", "SurveyQuestions")
      surveyQuestionFormData.append("Id", this.surveyQuestionId)
      surveyQuestionFormData.append("fields", JSON.stringify(this.surveyQuestionData))

      this.axios
        .post(this.$store.state.WS_URL + "/", surveyQuestionFormData)
        .then((response) => {

          if (response.status == 200) {
            if (response.data.status == "OK") {

              this.surveyQuestionData = {
                  Type: 'text',
                  Question: null,
                  Options: null,
                  }
              
              this.jobPostingSurveyQuestionId = null
              this.surveyQuestionId = response.data.Id
              
              this.saveJobPostingSurveyQuestion()
            } else {
              
              alert(
                "Problemas al guardar pregunta: " + response.data.message
              )
              
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        })
    },

    saveJobPostingSurveyQuestion() {
      const jobPostingSurveyQuestionFormData = new FormData()
      jobPostingSurveyQuestionFormData.append("action", "upsert")
      jobPostingSurveyQuestionFormData.append("t", this.getToken())
      jobPostingSurveyQuestionFormData.append("table", "JobPostingSurveyQuestions")
      jobPostingSurveyQuestionFormData.append("Id", this.jobPostingSurveyQuestionId)
      this.jobPostingSurveyQuestionData.JobPosting = this.Id
      this.jobPostingSurveyQuestionData.SurveyQuestion = this.surveyQuestionId
      jobPostingSurveyQuestionFormData.append("fields", JSON.stringify(this.jobPostingSurveyQuestionData))

      this.axios
        .post(this.$store.state.WS_URL + "/", jobPostingSurveyQuestionFormData)
        .then((response) => {

          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.jobPostingSurveyQuestionData = {
                  SurveyQuestionOrder: 99,
                  Status: 'active',
                  }
              this.surveyQuestionId = null
              this.getSurveyQuestions()
            } else {
              
              alert(
                "Problemas al guardar pregunta: " + response.data.message
              )
              
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        })
    },

    getSurveyQuestions() {
      let newSurveyQuestionOption = {value: null, text: 'Nueva pregunta', disabled: false}
      this.surveySurveyQuestions = [newSurveyQuestionOption]

      let spanishFilter = ''
      if (this.jobPostingData.Language == 'Spanish'){
        spanishFilter = " AND Question LIKE '¿%' "
      }

      const contactData = new FormData();
      contactData.append("action", "list");
      contactData.append("t", this.getToken());
      contactData.append("query", "SELECT * FROM SurveyQuestions WHERE RecordStatus = 'active' "+spanishFilter+" ORDER BY CreatedOn DESC " );
      this.axios
        .post(this.$store.state.WS_URL + "/", contactData)
        .then((response) => {
          //console.log(response.status, response.data, this.logPrefix + '>getSurveyQuestions');
          if (response.status == 200) {
            if (response.data.status == "OK") {
              response.data.records.forEach( (surveyQuestion) => {
                  surveyQuestion.value = surveyQuestion.Id
                  surveyQuestion.text = surveyQuestion.Question
                  surveyQuestion.disabled = false
                  this.surveySurveyQuestions.push(surveyQuestion)
              })

              this.getJobPostingSurveyQuestions()
            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });
    },

    getJobPostingSurveyQuestions() {
      const contactData = new FormData();
      contactData.append("action", "list");
      contactData.append("t", this.getToken());
      contactData.append("query", "SELECT * FROM JobPostingSurveyQuestions WHERE JobPosting = '"+this.Id+"' AND RecordStatus = 'active' AND Status = 'active' ORDER BY SurveyQuestionOrder ASC, CreatedOn ASC " );
      this.axios
        .post(this.$store.state.WS_URL + "/", contactData)
        .then((response) => {
          //console.log(response.status, response.data, this.logPrefix + '>getJobPostingSurveyQuestions');
          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.jobPostingSurveyQuestions = response.data.records

              //Disable surveySurveyQuestions already in the Job Posting
              this.jobPostingSurveyQuestions.forEach( (jobPostingSurveyQuestionItem) => {
                  this.surveySurveyQuestions.forEach( (surveyQuestionItem) => {
                      //console.log(surveyQuestionItem.Id, jobPostingSurveyQuestionItem.SurveyQuestion, (surveyQuestionItem.Id == jobPostingSurveyQuestionItem.SurveyQuestion),this.logPrefix + '>getJobPostingSurveyQuestions');
                      if (surveyQuestionItem.Id == jobPostingSurveyQuestionItem.SurveyQuestion){
                          surveyQuestionItem.disabled = true
                      }
                  })
              })

            }else{
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },

    getSurveyQuestion(_surveyQuestionId){
      //console.log('_surveyQuestionId', _surveyQuestionId, this.logPrefix + '>getSurveyQuestion')
      //console.log('this.surveySurveyQuestions', this.surveySurveyQuestions, this.logPrefix + '>getSurveyQuestion')
      
      let surveyQuestionFound = this.surveySurveyQuestions.find( ({ Id }) => Id === _surveyQuestionId )

      //console.log('surveyQuestionFound', surveyQuestionFound, this.logPrefix + '>getSurveyQuestion')

      if (surveyQuestionFound == undefined || surveyQuestionFound == null){
        surveyQuestionFound = {Question: 'NOT FOUND', Type: null}
      }

      return surveyQuestionFound
    },

    selectSurveyQuestion(){
        //console.log('this.surveyQuestionId', this.surveyQuestionId, this.logPrefix + '>selectSurveyQuestion');
        if (this.surveyQuestionId!=null){
          this.surveyQuestionData = this.getSurveyQuestion(this.surveyQuestionId)
        }else{
          this.surveyQuestionData = {
            Type: 'text',
            Question: null,
            Options: null,
          }
        }

        this.jobPostingSurveyQuestionId = null
        this.jobPostingSurveyQuestionData = {
              SurveyQuestionOrder: 99,
              Status: 'active',
              }
    },

    editJobPostingSurveyQuestion(_jobPostingSurveyQuestion){
      //console.log('_jobPostingSurveyQuestion', _jobPostingSurveyQuestion, this.logPrefix + '>editJobPostingSurveyQuestion')

      this.surveyQuestionId = _jobPostingSurveyQuestion.SurveyQuestion
      this.selectSurveyQuestion()
      this.jobPostingSurveyQuestionId = _jobPostingSurveyQuestion.Id
      this.jobPostingSurveyQuestionData = _jobPostingSurveyQuestion

      //console.log('this.jobPostingSurveyQuestionData', this.jobPostingSurveyQuestionData, this.logPrefix + '>editJobPostingSurveyQuestion')

      document.getElementById("new-surveyQuestion").scrollIntoView()

    },

    cancel(){
      if (this.mode == 'edit'){
        this.mode = 'view'
      }else if (this.mode == 'new'){
        this.$router.push('/JobPostings')
      }
    },

    getApplications() {
      const formData = new FormData()
      formData.append("action", "list")
      formData.append("t", this.getToken())
      formData.append("query", 'SELECT * FROM Applications_Basic_Info WHERE JobPosting = "'+this.jobPostingData.Id+'"')
      this.axios
        .post(this.$store.state.WS_URL + "/", formData)
        .then((response) => {

          //console.log('data', response.data)

          if (response.status == 200) {

            if (response.data.status == "OK") {
              this.applications = response.data.records
            } else {
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },

    getCampaigns() {
      const formData = new FormData()
      formData.append("action", "list")
      formData.append("t", this.getToken())
      formData.append("query", 'SELECT c.*, j.Name jobPostingName FROM Campaigns c, JobPostings j WHERE c.JobPosting = j.Id AND c.RecordStatus!="deleted" AND c.JobPosting = "' + this.jobPostingData.Id + '"')
      this.axios
        .post(this.$store.state.WS_URL + "/", formData)
        .then((response) => {

          //console.log('data', response.data)

          if (response.status == 200) {

            if (response.data.status == "OK") {
              this.campaigns = response.data.records
            } else {
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },


  },

  mounted: function () {
    if (this.$route.params.Id != undefined) {
      this.Id = this.$route.params.Id
      this.getJobPosting()
    } else {
      this.mode = "new"
      this.setPageTitle("New Job Posting")
      this.getSurveyQuestions()
    }

    this.getJobsIcons()


  },

  mixins: [common],
};
</script>

<style>
#jobIcons{
  width: 90%;
  height: 100vh;
  position: fixed;
  z-index: 1;
  background-color: #0000003d;
  top: 0;
}

#jobIcons > div{
  width: 75%;
  height: 90vh;
}

.jobIcon:hover{
  border: #bbb5b5;
  border-style: solid;
}
</style>
