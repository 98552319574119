<template>
  <div>
    <div class="container">
      <h1 v-if="Id">
        <a v-if="profilePicture" 
          :href="$store.state.UPLOADS_URL + '/' + contactUploadsFolderName + '/' +profilePicture" target="_blank">
          <img 
            :src="$store.state.UPLOADS_URL + '/' + contactUploadsFolderName + '/' +profilePicture" 
            style="max-width:50px;" />
        </a>
          <b-icon-person v-else /> 
        Contacto
      </h1>
      <h1 v-else><b-icon-person-plus /> Nuevo Contacto</h1>
    </div>

    <!--<ACTIONS>-->
    <div class="container my-3">
      <div v-if="mode == 'view'">

        <button class="btn btn-primary float-right" @click="mode = 'edit'">
          <b-icon-pencil />
        </button>

        <button class="btn btn-danger float-right mx-2" @click="deleteContact">
          <b-icon-file-earmark-minus-fill />
        </button>
      </div>
      <div v-else>
        <button class="btn btn-action float-right mx-2" @click="saveContact">
          <b-icon-file-check />
        </button>
        <button class="btn btn-secondary float-right mx-2" @click="cancel">
          <b-icon-file-x />
        </button>
      </div>
    </div>
    <!--</ACTIONS>-->

    <div style="clear: both"></div>

    <!--<FORM_DATA>-->
    <div class="container">
      <div class="row">
        <div class="col-sm my-3">
          <input
            :disabled="mode == 'view'"
            type="text"
            class="form-control"
            placeholder="Primer Nombre"
            v-model="contactData.FirstName"
          />
        </div>
        <div class="col-sm my-3">
          <input
            :disabled="mode == 'view'"
            type="text"
            class="form-control"
            placeholder="Primer Apellido"
            v-model="contactData.LastName"
          />
        </div>
      </div>

      <div class="row">
        
        <!-- 
        <div class="col-sm my-3">
          <input
            :disabled="mode == 'view'"
            type="text"
            class="form-control"
            placeholder="Telefono - e.g. 5057442345"
            v-model="contactData.Phone"
            @change="validatePhone($event)"
          />
        </div>
        -->
      </div>

      <!-- 
      <div class="row">
        <div class="col-sm my-3">
          <b-form-select
            v-model="contactData.Sex"
            class="form-select"
            :options="optionsSex"
            :disabled="mode == 'view'"
          />
        </div>
        <div class="col-sm my-3">
          <input
            :disabled="mode == 'view'"
            type="text"
            class="form-control"
            placeholder="Email"
            v-model="contactData.Email"
          />
        </div>
      </div>
      -->

      <div class="row">

        <div class="col-sm my-3">
          <input
            :disabled="mode == 'view'"
            type="text"
            class="form-control"
            placeholder="Celular - e.g. 5057442345"
            @change="validatePhone($event)"
            v-model="contactData.Cellphone"
          />
        </div>
        
        <div class="col-sm my-3">
          <b-form-select
            v-model="contactData.Status"
            class="form-select"
            :options="optionsStatus"
            :disabled="mode == 'view'"
          />
        </div>
        <div class="col-sm my-3">
          <b-form-select
            v-model="contactData.SmsConsent"
            class="form-select"
            :options="optionsSmsConsent"
            :disabled="mode == 'view'"
          />
        </div>
      </div>
    </div>
    <!--</FORM_DATA>-->

    <!--<TABS>-->
    <div class="container my-2" v-if="Id">
      <div class="row">
        <b-tabs content-class="mt-3">
          <b-tab title="Notas" active>
            <div class="row">
              <div class="col-sm my-3">
                <textarea
                  id="Note"
                  type="text"
                  class="form-control my-2"
                  placeholder="Agregar una nota"
                  v-model="noteData.Note"
                />
                
                <button
                  class="btn btn-action float-right my-2"
                  @click="saveNote"
                >
                  <b-icon-file-check />
                </button>
              </div>
              <div class="col-sm my-3">
                
              </div>
            </div>

            <div class="row">
              <div class="container">
                <div v-for="iNote in notes" :key="iNote.Id" class="row border-bottom py-3 grid-row">
                  <div class="col-sm-9"> {{iNote.Note}} <b-icon-pencil class="link" @click="editNote(iNote.Id)" />  </div>
                  <div class="col-sm"> 
                    <span v-html="getUsername(iNote.CreatedBy)" />
                    <br>
                    {{iNote.CreatedOn}} 
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab :title="'Archivos ('+contactFiles.length+')'">
            <div class="container">

              <input
                type="file"
                name="filesToUpload"
                id="filesToUpload"
                class="d-none"
                ref="filesToUpload"
                v-on:change="handleFilesUpload($event)"
                multiple
              />

              <div
                class="bg-light border round py-3 text-center link"
                @click="selectFiles"
              >
                  <b-icon-cloud-arrow-up/>

                <span v-html="addYourPicturesLabel" class="mx-2" />

                <span
                  v-if="filesToUpload.length > 0"
                  v-html="'(' + filesToUpload.length + ' archivo)'"
                />
              </div>

            </div>

            <div class="container">
              <div class="row">
                <div v-for="file in contactFiles" :key="file.Id" class="col-sm-6 border-bottom py-3">
                  <div class="d-flex">
                    <a :href="$store.state.UPLOADS_URL + '/' + contactUploadsFolderName + '/' + file" 
                      target="_blank"
                      class="link-grey">
                      <div v-if="file.includes('.pdf')" > <b-icon-file-earmark-richtext /> {{file}} </div>
                      <div v-else > 
                        <img 
                        :src="$store.state.UPLOADS_URL + '/' + contactUploadsFolderName + '/' +file" 
                        style="max-width:50px;" />  
                      </div>
                    </a>
                    <div @click="deleteFile(file)" class="h2 danger"><b-icon-x /> </div>
                  </div>
                </div>
              </div>

            </div>

          </b-tab>
          <b-tab :title="'Aplicaciones ('+applications.length+')'">
            
            <div v-for="app in applications" :key="app.Id" class="row border-bottom py-3 grid-row">
              <div class="col"> {{app.JobPostingName}}  </div>
              <div class="col"> Respuestas ({{app.answers}}) </div>
              <div class="col"> {{app.CreatedOn}} </div>
              <div class="col"> <router-link :to="'/application/'+app.Id"> <b-icon-eye /> </router-link> </div>
            </div>

          </b-tab>
          <b-tab :title="'Campañas de SMS ('+campaigns.length+')'">
            <div v-for="camp in campaigns" :key="camp.Id" class="row border-bottom py-3 grid-row">
              <div class="col"> {{camp.CampaignName}}  </div>
              <div class="col"> {{camp.JobPostingName}} </div>
              <div class="col"> {{camp.SentOn}} </div>
              <div class="col"> <router-link :to="'/campaign/'+camp.Campaign"> <b-icon-eye /> </router-link> </div>
            </div>
          </b-tab>

          <b-tab title="Historial" v-if="false"><p>I'm a disabled tab!</p></b-tab>
        </b-tabs>
      </div>

    </div>
    <!--</TABS>-->

    <!--<SYSTEM_PROPS>-->
    <div v-if="Id" class="container border-top border-bottom py-3 text-grey-900" >
      <h4>Propiedades del sistema</h4>

      <div class="row">
        <div class="col-sm-2 font-weight-bold">Id</div>
        <div class="col-sm">
          <span v-html="this.Id" />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2 font-weight-bold">Creacion</div>
        <div class="col-sm">
          <span v-html="contactData.CreatedOn" />
        </div>

        <div class="col-sm-2 font-weight-bold">Creado por</div>
        <div class="col-sm">
          <span v-html="getUsername(contactData.CreatedBy)" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 font-weight-bold">Ultima modificacion</div>
        <div class="col-sm">
          <span v-html="contactData.ModifiedOn" />
        </div>

        <div class="col-sm-2 font-weight-bold">Modificado por</div>
        <div class="col-sm">
          <span v-html="getUsername(contactData.ModifiedBy)" />
        </div>
      </div>
    </div>
    <!--</SYSTEM_PROPS>-->
  </div>
</template>

<script>
import common from "@/mixins/common";

export default {
  data() {
    return {
      logPrefix: "NewContact",
      mode: "view",
      optionsStatus: [
        { value: "new", text: "Nuevo" },
        { value: "employed", text: "Empleado" },
        { value: "terminated", text: "Despedido" },
        { value: "resigned", text: "Renuncia" },
      ],

      

      optionsSex: [
        { value: "male", text: "Hombre" },
        { value: "mujer", text: "Mujer" },
        { value: "desconocido", text: "Desconocido" },
      ],


      Id: null,
      contactData: {
        FirstName: "",
        LastName: "",
        Cellphone: "",
        /*Phone: null,
        Sex: "desconocido",
        Email: null,
        */
        Status: "new",
        SmsConsent: "pending",
      },
      
      NoteId: null,
      noteData:{
        Note: null,
        ObjectType: 'Contact',
        ObjectId: null,
      },
      notes: null,

      contactFiles: "",
      profilePicture: null,
      applications: [],
      campaigns: [],


      filesToUpload: "",


    };
  },

  computed: {
    addYourPicturesLabel() {
      
      return "Agregar archivos";
    },

    contactUploadsFolderName(){
      return 'Contact-' + this.Id
    }
  },


  watch: {
    mode: {
      handler(val) {
        if (val == "edit") {
          this.contactData.Cellphone = this.cleanUpPhone(this.contactData.Cellphone)
          //this.contactData.Phone = this.cleanUpPhone(this.contactData.Phone)
        }else{
          this.contactData.Cellphone = this.maskPhone(this.contactData.Cellphone)
          //this.contactData.Phone = this.maskPhone(this.contactData.Phone)
        }
      },
      deep: true,
    },
  },

  methods: {
    saveContact() {
      const contactData = new FormData();
      contactData.append("action", "upsert");
      contactData.append("t", this.getToken());
      contactData.append("table", "Contacts");
      contactData.append("Id", this.Id);

      if (this.contactData.Cellphone != null){  
        this.contactData.Cellphone = this.cleanUpPhone(this.contactData.Cellphone)
      }

      /*
      if (this.contactData.Phone != null){  
        this.contactData.Phone = this.cleanUpPhone(this.contactData.Phone)
      }
      */

      contactData.append("fields", JSON.stringify(this.contactData));

      this.axios
        .post(this.$store.state.WS_URL + "/", contactData)
        .then((response) => {
          //console.log(response.data);

          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.Id = response.data.Id;
              this.mode = 'view'
            } else {
              if (response.data.message.includes("Contact_Cellphone_Name")) {
                alert("Contacto Duplicado!");
              } else {
                alert(
                  "Problemas al guardar contacto: " + response.data.message
                );
              }
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },

    saveNote() {
      const contactData = new FormData();
      contactData.append("action", "upsert");
      contactData.append("t", this.getToken());
      contactData.append("table", "Notes");
      contactData.append("Id", this.NoteId);

      this.noteData.ObjectId = this.Id
      contactData.append("fields", JSON.stringify(this.noteData));

      this.axios
        .post(this.$store.state.WS_URL + "/", contactData)
        .then((response) => {
          //console.log(response.data);

          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.noteData.Note = null
              this.noteId = null
              this.getNotes()
            } else {
              alert(
                  "Problemas al guardar nota: " + response.data.message
                );
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });
    },

    deleteContact() {
      if (confirm('Seguro que desea borrar contacto?')) {
        this.contactData.RecordStatus = 'deleted'
        this.contactData.FirstName = this.contactData.FirstName + '-DELETED-' + this.contactData.Id
        this.saveContact()
        this.$router.push('/contacts')
      }
    },

    getContact() {
      const contactData = new FormData();
      contactData.append("action", "list");
      contactData.append("t", this.getToken());
      contactData.append("query", "SELECT * FROM Contacts WHERE Id = '" + this.Id + "'");
      this.axios
        .post(this.$store.state.WS_URL + "/", contactData)
        .then((response) => {
          //console.log(response.status, response.data);
          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.contactData = response.data.records[0];
              this.contactData.Cellphone = this.contactData.Cellphone.replace(this.phoneExp, "$1-$2-$3")
              //this.contactData.Phone = this.contactData.Phone.replace(this.phoneExp, "$1-$2-$3")

              this.setPageTitle( this.contactData.FirstName + ' ' + this.contactData.LastName )

              this.getApplications()

              this.getCampaigns()


            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });
    },


    getApplications() {
      const formData = new FormData()
      formData.append("action", "list")
      formData.append("t", this.getToken())
      formData.append("query", 'SELECT * FROM Applications_Basic_Info WHERE Contact = "'+this.Id+'"')
      this.axios
        .post(this.$store.state.WS_URL + "/", formData)
        .then((response) => {

          //console.log('data', response.data)

          if (response.status == 200) {

            if (response.data.status == "OK") {
              this.applications = response.data.records
            } else {
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },

    getCampaigns() {
      const formData = new FormData()
      formData.append("action", "list")
      formData.append("t", this.getToken())
      formData.append("query", 'SELECT * FROM CampaignRecipientsInfo WHERE Contact = "'+this.Id+'"')
      this.axios
        .post(this.$store.state.WS_URL + "/", formData)
        .then((response) => {

          //console.log('data', response.data)

          if (response.status == 200) {

            if (response.data.status == "OK") {
              this.campaigns = response.data.records
            } else {
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },

    getNotes() {
      const contactData = new FormData();
      contactData.append("action", "list");
      contactData.append("t", this.getToken());
      contactData.append("query", "SELECT * FROM Notes WHERE ObjectId = '" + this.Id + "' ORDER BY CreatedOn DESC" );
      this.axios
        .post(this.$store.state.WS_URL + "/", contactData)
        .then((response) => {
          //console.log(response.status, response.data);
          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.notes = response.data.records;
            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });
    },

    editNote(noteId){
      //console.log('editNote', noteId)
      this.notes.forEach( (element) => {
          if (element.Id == noteId){
              this.NoteId = element.Id
              this.noteData = element
          }
      })
      document.getElementById("Note").scrollIntoView();

    },

    cancel(){
      if (this.mode == 'edit'){
        this.mode = 'view'
      }else if (this.mode == 'new'){
        this.$router.push('/contacts')
      }
    },

    handleFilesUpload(event) {
      this.filesToUpload = event.target.files
      //console.log(this.filesToUpload)
      this.uploadFiles()
    },

    selectFiles() {
      const elem = this.$refs.filesToUpload
      elem.click()
    },

    uploadFiles(){
      let formData = new FormData();

      formData.append("action", "uploadFiles")
      formData.append("folder", this.contactUploadsFolderName)
      formData.append("t", this.getToken())
      

      for (var i = 0; i < this.filesToUpload.length; i++) {
        let file = this.filesToUpload[i];
        formData.append("files[" + i + "]", file);
      }

      this.axios
          .post(this.$store.state.WS_URL + "/", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => {
            if (response.data.status == "OK") {
              this.getFiles()
            } else {
              console.log('response', response)
              console.log('status', response.status)
              console.log('data', response.data)
              if (response.data.status.includes('file-error-ext')){
                alert('Extension de archivo no permitida')
              }
              this.handleWsErr(response.data);
            }
            
          });
    },

    getFiles(){
      const fileData = new FormData();
      fileData.append("action", "listFiles");
      fileData.append("t", this.getToken());
      fileData.append("folder", this.contactUploadsFolderName );
      this.axios
        .post(this.$store.state.WS_URL + "/", fileData)
        .then((response) => {
          //console.log(response.status, response.data);
          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.contactFiles = response.data.files

              this.contactFiles.forEach( (iFile) => {
                if (iFile.includes('foto')){
                  this.profilePicture = iFile
                }
              })
              
            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });
    },

    deleteFile(file){
      if ( !confirm('Seguro que desea borrar este archivo?')) {
        return null
      }
      const fileData = new FormData();
      fileData.append("action", "deleteFile");
      fileData.append("t", this.getToken());
      fileData.append("folder", this.contactUploadsFolderName );
      fileData.append("file", file );
      this.axios
        .post(this.$store.state.WS_URL + "/", fileData)
        .then((response) => {
          //console.log(response.status, response.data);
          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.getFiles()
            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });
    },


  },

  mounted: function () {
    if (this.$route.params.Id != undefined) {
      this.Id = this.$route.params.Id
      this.getContact()
      this.getNotes()
      this.getFiles()
    } else {
      this.mode = "new"
      this.setPageTitle("New Contact")
    }
  },

  mixins: [common],
};
</script>

<style></style>
