<template>
  <div>
    <div class="container">
      <div v-if="Id" class="row">
        <div class="col-sm text-left">
          <h1>Pregunta</h1>
        </div>
      </div>
      <h1 v-else><b-icon-plus-circle /> Nueva Pregunta</h1>
    </div>

    <!--<ACTIONS>-->
    <div class="container my-3">
      <div v-if="mode == 'view'">

        <button class="btn btn-primary float-right" @click="mode = 'edit'">
          <b-icon-pencil />
        </button>

        <button class="btn btn-danger float-right mx-2" @click="deleteSurveyQuestion">
          <b-icon-file-earmark-minus-fill />
        </button>
      </div>
      <div v-else>
        <button class="btn btn-action float-right mx-2" @click="saveSurveyQuestion">
          <b-icon-file-check />
        </button>
        <button class="btn btn-secondary float-right mx-2" @click="cancel">
          <b-icon-file-x />
        </button>
      </div>
    </div>
    <!--</ACTIONS>-->

    <div style="clear: both"></div>

    <!--<FORM_DATA>-->
    <div class="container mb-5">
      <div class="row">
        <div class="col-sm my-3">
          <input
            :disabled="mode == 'view'"
            type="text"
            class="form-control"
            placeholder="Nombre"
            v-model="surveyQuestionData.Question"
          />
        </div>

      </div>
      

      <div class="row">
        <div class="col-sm my-3">
          <b-form-select
            v-model="surveyQuestionData.Type"
            class="form-select"
            :options="surveyQuestionTypes"
            :disabled="mode == 'view'"
          />
        </div>

        <div class="col-sm my-3">
          <textarea
              type="text"
              rows="3"
              :disabled="mode == 'view'"
              class="form-control"
              placeholder="Opciones (Una por linea)"
              v-model="surveyQuestionData.Options"
              v-if="surveyQuestionData.Type=='select'"
            ></textarea>
          
        </div>

        

        
        
      </div>


   
    </div>
    <!--</FORM_DATA>-->
    
    <div style="clear:both"></div>


    <!--<TABS>-->
    <div class="container my-2" v-if="surveyQuestionApplications.length>0">
      <div class="row">
        <b-tabs content-class="mt-3">
          <b-tab :title="'Aplicaciones ('+surveyQuestionApplications.length+')'" active>

            <div class="container mb-3">
              <input type="text" v-model="textToSearch" placeholder="Texto a buscar"
              v-on:keyup="textSearch()"  />
            </div>
            
            <!--<APPLICATIONS>-->
            <div class="container">
              <div v-for="iApplication in surveyQuestionApplicationsFound" :key="iApplication.Id" :id="iApplication.Id" class="row border-bottom py-3 grid-row">
                <div class="col-sm-9"> 
                  <b>{{ iApplication.JobPostingName }} </b> 
                    <router-link :to="'/application/'+iApplication.Application" class="link"> <b-icon-eye /></router-link>
                  <br>{{ iApplication.FirstName }} {{ iApplication.LastName }} 
                    <router-link :to="'/contact/'+iApplication.Contact" class="link"> <b-icon-eye /></router-link>
                  <br>Respuesta: {{ iApplication.Response }} 
                     
                </div>
              </div>
            </div>
            <!--</JOB_POSTING_QUESTIONS>-->

            


          </b-tab>
          
      
        </b-tabs>
      </div>

    </div>
    <!--</TABS>-->

    
    <!--<SYSTEM_PROPS>-->
    <div v-if="Id" class="container border-top border-bottom py-3 text-grey-900" >
      <h4>Propiedades del sistema</h4>

      <div class="row">
        <div class="col-sm-2 font-weight-bold">Id</div>
        <div class="col-sm">
          <span v-html="this.Id" />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2 font-weight-bold">Creacion</div>
        <div class="col-sm">
          <span v-html="surveyQuestionData.CreatedOn" />
        </div>

        <div class="col-sm-2 font-weight-bold">Creado por</div>
        <div class="col-sm">
          <span v-html="getUsername(surveyQuestionData.CreatedBy)" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 font-weight-bold">Ultima modificacion</div>
        <div class="col-sm">
          <span v-html="surveyQuestionData.ModifiedOn" />
        </div>

        <div class="col-sm-2 font-weight-bold">Modificado por</div>
        <div class="col-sm">
          <span v-html="getUsername(surveyQuestionData.ModifiedBy)" />
        </div>
      </div>
    </div>
    <!--</SYSTEM_PROPS>-->


  </div>
</template>

<script>
import common from "@/mixins/common";

export default {
  data() {
    return {
      logPrefix: "SurveyQuestion",
      mode: "view",
      
      Id: null,
      surveyQuestionData: {
        Type: 'text',
        RecordStatus: 'active'
      },
      surveyQuestionApplications: [],
      surveyQuestionApplicationsFound: [],

      textToSearch: ""
      
    };
  },


  methods: {

    getSurveyQuestion() {
      const surveyQuestionData = new FormData();
      surveyQuestionData.append("action", "list");
      surveyQuestionData.append("t", this.getToken());
      surveyQuestionData.append("query", "SELECT * FROM SurveyQuestions WHERE Id = '" + this.Id + "'");
      this.axios
        .post(this.$store.state.WS_URL + "/", surveyQuestionData)
        .then((response) => {
          //console.log('response.data', response.data, this.logPrefix + '>getJobPosting');
          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.surveyQuestionData = response.data.records[0]

             
              this.getSurveyQuestionApplications()
              this.setPageTitle( this.surveyQuestionData.Question )

            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });
    },

    saveSurveyQuestion() {
      const surveyQuestionFormData = new FormData();
      surveyQuestionFormData.append("action", "upsert");
      surveyQuestionFormData.append("t", this.getToken());
      surveyQuestionFormData.append("table", "SurveyQuestions");
      surveyQuestionFormData.append("Id", this.Id);

      surveyQuestionFormData.append("fields", JSON.stringify(this.surveyQuestionData));

      this.axios
        .post(this.$store.state.WS_URL + "/", surveyQuestionFormData)
        .then((response) => {

          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.Id = response.data.Id
              this.mode = 'view'
              this.getSurveyQuestion()
            } else {
              
              alert(
                "Problemas al guardar la pregunta: " + response.data.message
              )
              
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },

    deleteSurveyQuestion() {
      if (confirm('Seguro que desea borrar esta pregunta?')) {
        this.surveyQuestionData.RecordStatus = 'deleted'
        this.surveyQuestionData.Question = this.surveyQuestionData.Question + '-DELETED'
        this.saveSurveyQuestion()
        this.$router.push('/surveyQuestions')
      }
    },

    getSurveyQuestionApplications() {
      const surveyQuestionData = new FormData();
      surveyQuestionData.append("action", "list");
      surveyQuestionData.append("t", this.getToken());
      surveyQuestionData.append("query", "SELECT * FROM JobPostingSurveyResponsesInfo WHERE Question = '" + this.Id + "'");
      this.axios
        .post(this.$store.state.WS_URL + "/", surveyQuestionData)
        .then((response) => {
          //console.log('response.data', response.data, this.logPrefix + '>getJobPosting');
          if (response.status == 200) {
            if (response.data.status == "OK") {
              this.surveyQuestionApplications = response.data.records
              this.surveyQuestionApplicationsFound = response.data.records
            } else {
              this.handleWsErr(response.data);
            }
          } else {
            console.log(response);
          }
        });
    },

    cancel(){
      if (this.mode == 'edit'){
        this.mode = 'view'
      }else if (this.mode == 'new'){
        this.$router.push('/JobPostings')
      }
    },

    textSearch() {
      this.surveyQuestionApplicationsFound = []
        
      if (!this.textToSearch){
        this.surveyQuestionApplicationsFound = this.surveyQuestionApplications
        return 
      }

      let textToSearch = this.textToSearch.toLowerCase()
      
      this.surveyQuestionApplications.forEach(iApplication => {

        let jobApplicationContent = 
            iApplication.JobPostingName.toLowerCase()
            + ' ' +iApplication.FirstName.toLowerCase()
            + ' ' +iApplication.LastName.toLowerCase()
            + ' ' +iApplication.Response.toLowerCase()
        
        console.log(textToSearch, jobApplicationContent, this.LevenshteinDistance(textToSearch, jobApplicationContent))
        if (this.LevenshteinDistance(textToSearch, jobApplicationContent) <= 1
            || jobApplicationContent.includes(textToSearch)

        ){

          this.surveyQuestionApplicationsFound.push(iApplication);
        }

      })
    },

    LevenshteinDistance(a, b) {
      if (a.length == 0) return b.length;
      if (b.length == 0) return a.length;

      var matrix = [];

      // increment along the first column of each row
      var i;
      for (i = 0; i <= b.length; i++) {
        matrix[i] = [i];
      }

      // increment each column in the first row
      var j;
      for (j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
      }

      // Fill in the rest of the matrix
      for (i = 1; i <= b.length; i++) {
        for (j = 1; j <= a.length; j++) {
          if (b.charAt(i - 1) == a.charAt(j - 1)) {
            matrix[i][j] = matrix[i - 1][j - 1];
          } else {
            matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, // substitution
              Math.min(matrix[i][j - 1] + 1, // insertion
                matrix[i - 1][j] + 1)); // deletion
          }
        }
      }

      return matrix[b.length][a.length];
    },

  },

  mounted: function () {
    if (this.$route.params.Id != undefined) {
      this.Id = this.$route.params.Id
      this.getSurveyQuestion()
    } else {
      this.mode = "new"
      this.setPageTitle("New Survey Question")
    }

  },

  mixins: [common],
};
</script>


