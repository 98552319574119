import Vue from 'vue'

/** Store **/
import Vuex from 'vuex'
Vue.use(Vuex)

//let logSource = 'store'

const state = {
    WS_URL: "https://admin.santiempleo.com/s/",
    PUBLIC_SITE_URL: "https://santiempleo.com",
    IMG_URL: "https://admin.santiempleo.com/img",
    UPLOADS_URL: "https://admin.santiempleo.com/uploads",

    user: null,
    users: null,

    windowProps: null,
    lastUserActivity: null,
    lastTokenRefresh: null
}

const getters = {
    isLogged: state => {
        if (state.user != null){
            return true
        }

        return false
    },
    windowProps: state => {
        return state.windowProps
    },
    user: state => {
        return state.user
    },
    users: state => {
        return state.users
    },
    lastUserActivity: state => {
        return state.lastUserActivity
    },
    lastTokenRefresh: state => {
        return state.lastTokenRefresh
    }
}

const mutations = {
    user (state, user) {
        //let logTrace = logSource + '.user'
        //console.log('user', user, logTrace)

        state.user = user
    },
    users (state, users) {
        //let logTrace = logSource + '.user'
        //console.log('users', users, logTrace)

        state.users = users
    },
    windowProps (state, windowProps) {
        //let logTrace = logSource + '.windowProps'
        //console.log('user', windowProps, logTrace)

        state.windowProps = {
           "width": windowProps.innerWidth,
           "height": windowProps.innerHeight
        }
    },
    lastUserActivity (state) {
        //let logTrace = logSource + '.lastUserActivity'
        //console.log('user', Date.now(), logTrace)
        state.lastUserActivity =  Date.now()
    },
    lastTokenRefresh (state, lastTokenRefresh) {

        state.lastTokenRefresh =  lastTokenRefresh
    },
}
const actions = {}


export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions
})
  