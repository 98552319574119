<template>
  <div>
    <h1><b-icon-award />  
      Clientes
      <router-link to="/client" class="btn btn-primary m-2">
        <b-icon-plus-circle />
      </router-link>
    </h1>
    <div class="container">
      <div v-for="cnt in Clients" :key="cnt.Id" class="row border-bottom py-3 grid-row">
        <div class="col"> {{cnt.Name}} </div>
        <div class="col"> {{cnt.CreatedOn}} </div>
        <div class="col"> <router-link :to="'/client/'+cnt.Id"> <b-icon-eye /> </router-link> </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/mixins/common";
export default {
  data() {
      return {
          logPrefix: "Clients",
          Clients: null,
          };
  },
  methods: {
    getClients() {
      const formData = new FormData()
      formData.append("action", "list")
      formData.append("t", this.getToken())
      formData.append("query", 'SELECT * FROM Clients WHERE RecordStatus!="deleted"')
      this.axios
        .post(this.$store.state.WS_URL + "/", formData)
        .then((response) => {

          //console.log('data', response.data)

          if (response.status == 200) {

            if (response.data.status == "OK") {
              this.Clients = response.data.records
            } else {
              this.handleWsErr(response.data)
            }
          } else {
            console.log(response);
          }
        });
    },
  },
  mounted: function () {
    this.getClients()
    this.setPageTitle("Clients")

  },
  mixins: [common],
};
</script>

<style></style>
